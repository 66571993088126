import React, { useState, useContext, useEffect } from 'react';
import ReAddToCartModal from 'components/ReAddToCartModal';
import ConfirmDialog from 'pages/checkout/ConfirmDialog';
import countObjectValues from 'functions/countObjectValues';
import { sumTotalPriceWithPrepItem } from 'functions/sumTotalPriceWithPrepItem';
import CheckoutPage from 'pages/checkout/CheckoutPage';
import { useAuthContext } from 'context/AuthContext';
import { useRealmContext } from 'context/RealmContext';
import { useGeneralContext } from 'context/GeneralContext';
import { useHistory } from 'react-router-dom';
import localStorageNames from 'data/localStorageNames';
import { useTranslation } from 'react-i18next';
import QrCodeDialog from 'pages/checkout/QrCodeDialog';
import LoadingPayment from 'pages/checkout/LoadingPayment';
import { getPriceBySellBand } from 'functions/getItemPriceBySellBand';
import URLS from 'URLS';
import { fetchSplashConfig } from 'data/realmFetch';

const TOKEN_VALIDATOR =
  process.env[`REACT_APP_VALIDATION_${process.env.REACT_APP_ENV}`];

export const CartContext = React.createContext([{}, () => {}]);

const initialAddToCartModal = {
  visible: false,
  data: null,
};

const initialCheckoutPage = {
  visible: false,
};

const initialConfirmModal = {
  visible: false,
  titleDialog: '',
  messageDialog: '',
  orderMethod: null,
};

const initialConfirmPayment = {
  visible: false,
  titleDialog: '',
  messageDialog: '',
  data: null,
};

const initialQrcodeModal = {
  visible: false,
  data: null,
};

const initialSuggestionPage = {
  visible: false,
};

const initialState = {
  cartModal: initialAddToCartModal,
  checkoutPage: initialCheckoutPage,
  confirmModal: initialConfirmModal,
  confirmPayment: initialConfirmPayment,
  qrcodeModal: initialQrcodeModal,
  loadingPayment: false,
  loadingModal: false,
  suggestionPage: initialSuggestionPage,
  isRewardsPage: false,
  confirmExitRewards: false,
  cart: {
    items: [],
  },
  realmCart: {
    items: [],
  },
  urlXenditPage: null,
};

const CartProvider = ({ children }) => {
  const [state, setState] = useState(initialState);
  const history = useHistory();
  const {
    hitAPI,
    getCredential,
    getCurrentSalesType,
    getLocalStorage,
    getTableName,
    getUserPhone,
    getOutletInfo,
    getSetting,
    checkTokenIsExpired,
  } = useAuthContext();
  const { openErrorSnackBar, getSellBandId, showLoading } = useGeneralContext();
  const { t } = useTranslation();
  const {
    hitRealmTransaction,
    hitRealmTransaction2,
    sendClientPayment,
    isLastTransactionExist,
    checkMiniAppConnection,
  } = useRealmContext();

  const ID_OVO = 'ID_OVO';
  const ID_DANA = 'ID_DANA';
  const ID_LINKAJA = 'ID_LINKAJA';
  const ID_SHOPEEPAY = 'ID_SHOPEEPAY';
  const forceReset = () => {
    setState(initialState);
  };

  const isLoggedIn = () => {
    return getCredential() && getCurrentSalesType();
  };

  const setContextState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getContextState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const setConfirmModal = (value, orderMethod, parameter) => {
    setContextState({
      confirmModal: {
        visible: value,
        orderMethod: orderMethod,
        parameter: parameter,
      },
    });
  };

  const setConfirmPayment = (value, titleDialog, messageDialog, data) => {
    setContextState({
      confirmPayment: {
        visible: value,
        titleDialog: titleDialog,
        messageDialog: messageDialog,
        data: data,
      },
    });
  };

  const setCartItems = (newData) => {
    setContextState({
      cart: {
        items: newData,
      },
    });
  };

  const getCartItems = () => {
    let itemsData = [...getContextState('cart').items];
    return itemsData;
  };

  const getXenditPage = () => {
    const urlXendit = getContextState('urlXenditPage');
    return urlXendit;
  };

  const setCartItemLocalStorage = (newData) => {
    if (isLoggedIn()) {
      localStorage.setItem(
        localStorageNames.CART_ITEMS,
        JSON.stringify(newData),
      );
    }
  };

  const getCartItemLocalStorage = () => {
    let localCart = localStorage.getItem(localStorageNames.CART_ITEMS);
    return JSON.parse(localCart) || [];
  };

  const setCartModal = (value, data) => {
    setContextState({
      cartModal: {
        visible: value,
        data: data || null,
      },
    });
  };

  const setQrcodeModal = (value, data) => {
    setContextState({
      qrcodeModal: {
        visible: value,
        data: data || null,
      },
    });
  };

  const getCartModalData = () => {
    return { ...getContextState('cartModal').data };
  };

  const getCartModalVisibility = () => {
    return getContextState('cartModal').visible;
  };

  const setSuggestionPage = (value) => {
    setContextState({
      suggestionPage: {
        visible: value,
      },
    });
  };

  const getSuggestionPage = () => {
    return getContextState('suggestionPage').visible;
  };

  const setCheckoutPage = (value) => {
    setContextState({
      checkoutPage: {
        visible: value,
      },
    });
  };

  const getBadgeCount = () => {
    return countObjectValues(getCartItems(), 'quantity');
  };

  const insertProductDetail = (product) => {
    let productDetail = JSON.parse(
      getLocalStorage(`product.${product.uuid_product}`),
    );
    product.modifier_group = productDetail.modifier_group;
    product.prep_group = productDetail.prep_group;
    product.sub_product = productDetail.sub_product;
    product.variant_item = productDetail.variant_item;
    return product;
  };

  const removeItemInCart = (item) => {
    let items = getCartItems();
    items = items.filter((x) => x.timestamp !== item.timestamp);
    items = items.filter((oldItem) => oldItem.promoFrom !== item.timestamp);
    setCartItems(items);
  };

  const totalPrice = () => {
    let subTotal = 0;
    getCartItems().forEach((item) => {
      subTotal += sumTotalPriceWithPrepItem(
        item,
        item.quantity,
        getSellBandId(),
      );
    });
    return subTotal;
  };

  const totalQty = () => {
    return countObjectValues(getCartItems(), 'quantity');
  };

  const changeCartItemQuantity = (item, quantity) => {
    let items = [];
    let itemIndex = -1;
    items = getCartItems();
    itemIndex = items.findIndex(
      (x) =>
        x.uuid_product === item.uuid_product && x.timestamp === item.timestamp,
    );

    if (itemIndex !== -1) {
      items[itemIndex].quantity = quantity;
      setCartItems(items);
    }
  };

  const addToCart = (item, isUsingMock) => {
    let localPrepGroup = [];

    if (item.is_variant) {
      const variantChecked = item.variant_item.find((data) => data.checked);
      if (variantChecked) {
        if (variantChecked.prep_group && variantChecked.prep_group.length > 0) {
          localPrepGroup = [...variantChecked.prep_group];
        }
      }
    } else {
      localPrepGroup = [...item.prep_group];
    }
    // CEK DAN FILTER PREP GROUP YANG TERISI SAJA
    if (localPrepGroup && localPrepGroup.length > 0) {
      localPrepGroup = localPrepGroup
        .map((group) => {
          let localPrepItem = [...group.prep_item.filter((x) => x.checked)];
          group.prep_item = localPrepItem;
          if (group.prep_item.length > 0) {
            return group;
          }
          return null;
        })
        .filter((y) => y);
    }

    if (isUsingMock) {
      // HANYA KETIKA MENGGUNAKAN TOMBOL MOCK DI DEV PANEL
      setCartItems([...getCartItemLocalStorage(), item]);
    } else {
      setCartItems([...getCartItems(), item]);
    }

    if (item.is_have_promo) {
      let data = {
        uuid: item.uuid_product,
        stamp: item.timestamp,
        count: item.quantity,
        suggestion: item.is_have_suggestion,
      };
      setContextState({ isRewardsPage: true });
      history.replace(URLS.REWARDS, data);
    } else if (item.is_have_suggestion) {
      const dataSuggestion = item.uuid_product;
      setSuggestionPage(true);
      history.replace(URLS.SUGGESTION, dataSuggestion);
    }
  };

  const sendTransaction = async (orderMethod, parameter) => {
    if (orderMethod === 'payment_order') showLoading(true);

    let credential = getCredential() || {};
    let typesales = getCurrentSalesType() || {};
    let cartItem = [...getCartItems()];

    let data = {};
    let tokenValidation = {};

    if (Array.isArray(cartItem) && cartItem.length > 0) {
      data = {
        uuid_table: credential.uuid || credential.table,
        parameter: parameter,
        uuid_sales_type: typesales.id,
        order_items: cartItem.map((item) => {
          let prepGroup = [];
          let productId = '';
          let productName = '';
          //cek jika type variant
          if (item.is_variant) {
            const variantChecked = item.variant_item.find(
              (data) => data.checked,
            );
            productId = variantChecked.uuid_product;
            productName = variantChecked.plu_name;
            if (variantChecked) {
              if (
                variantChecked.prep_group &&
                variantChecked.prep_group.length > 0
              ) {
                prepGroup = [...variantChecked.prep_group];
              }
            }
          } else {
            prepGroup = item.prep_group;
            productId = item.uuid_product;
            productName = item.plu_name;
          }
          
          // Spread semua prep group kedalam 1 array of prep item
          let selectedPrepItem = [];
          if (prepGroup.length > 0) {
            // SPREAD PREP GROUP NYA
            prepGroup.map((group) => {
              selectedPrepItem = [...selectedPrepItem, ...group.prep_item];
              // menghindari error eslint (array.map harus return sesuatu)
              return null;
            });

            // MENGHILANGKAN KEY YANG TIDAK BERGUNA & MANIPULASI QTY
            selectedPrepItem = selectedPrepItem.map((prepItem) => {
              let localPrepItem = { ...prepItem };
              delete localPrepItem.checked;
              delete localPrepItem.use_quantity;
              if (orderMethod !== 'waiter_order') {
                delete localPrepItem.price;
              } else {
                localPrepItem['price'] = getPriceBySellBand(
                  localPrepItem,
                  getSellBandId(),
                );
              }
              delete localPrepItem.sell_bands;

              localPrepItem.quantity =
                localPrepItem.quantity !== null && localPrepItem.quantity > 0
                  ? localPrepItem.quantity * item.quantity
                  : 1;
              localPrepItem.product_name = localPrepItem.plu_name;
              return localPrepItem;
            });
          }

          return {
            uuid_product: productId,
            plu_name: item.plu_name,
            product_name: productName,
            quantity: item.quantity,
            note: item.additional_request || [],
            prep_items: selectedPrepItem,
          };
        }),
      };

      // CHECK PRODUCT AND TOKEN
      tokenValidation = await hitAPI(TOKEN_VALIDATOR, 'POST', credential, data);
    } else {
      // untuk payment order, jika tidak ada data baru (item product yang dipilih user) maka tidak dilakukan validasi
      // jadi tokenValidation diberi nilai
      tokenValidation.error = false;
    }

    const getQtyPrepItem = (prepItem, item) => {
      const fromViewBill = localStorage.getItem(localStorageNames.IS_VIEW_BILL);
      const qty = fromViewBill
        ? (prepItem.baseQuantity || prepItem.quantity) * item?.quantity
        : prepItem?.quantity * item?.quantity;

      return qty || 0;
    };

    if (tokenValidation) {
      if (tokenValidation.error === false) {
        let typesales = getCurrentSalesType() || {};
        let outletInfo = JSON.parse(getOutletInfo());
        let cartItem = [...getCartItems()];

        let data = {
          table_id: getSetting().isStore ? '' : outletInfo.tableId,
          table_name: getTableName() || tokenValidation.result.parameter,
          sales_type: getCurrentSalesType().type_sales_id.toString(),
          sales_name: getCurrentSalesType().title.toString(),
          sell_band: typesales.sell_band.toString(),
          no_telp: getUserPhone() || '',
          type_bill: orderMethod,
          order_items: cartItem.map((item) => {
            let prepGroup = [];
            let pluNumber = '';
            let productName = '';
            let _id = item._id;

            if (item.is_variant) {
              const variantChecked = item.variant_item.find(
                (data) => data.checked,
              );
              pluNumber = variantChecked.plu_number;
              productName = variantChecked.plu_name;
              if (variantChecked) {
                if (
                  variantChecked.prep_group &&
                  variantChecked.prep_group.length > 0
                ) {
                  prepGroup = [...variantChecked.prep_group];
                }
              }
            } else {
              prepGroup = item.prep_group || item.prep_items;
              pluNumber = item.plu_number;
              productName = item.plu_name;
            }

            // Spread semua prep group kedalam 1 array of prep item
            let selectedPrepItem = [];
            if (prepGroup.length > 0) {
              // SPREAD PREP GROUP NYA
              prepGroup.map((group) => {
                if (group._id && group._id !== null && group._id !== '') {
                  selectedPrepItem = [...selectedPrepItem, group];
                } else {
                  selectedPrepItem = [...selectedPrepItem, ...group.prep_item];
                }
                // menghindari error eslint (array.map harus return sesuatu)
                return null;
              });
              // MENGHILANGKAN KEY YANG TIDAK BERGUNA & MANIPULASI QTY
              selectedPrepItem = selectedPrepItem.map((prepItem) => {
                const amount = getPriceBySellBand(prepItem, getSellBandId());

                let localPrepItem = { ...prepItem };

                delete localPrepItem.checked;
                delete localPrepItem.use_quantity;
                delete localPrepItem.is_active;
                delete localPrepItem.uuid_product;
                delete localPrepItem._id;
                delete localPrepItem._partition;
                if (orderMethod !== 'waiter_order') {
                  delete localPrepItem.price;
                  delete localPrepItem.sell_bands;
                } else {
                  delete localPrepItem.amount;
                  localPrepItem['price'] = getPriceBySellBand(
                    localPrepItem,
                    getSellBandId(),
                  );

                  delete localPrepItem.sell_bands;
                }

                localPrepItem.quantity =
                  localPrepItem.quantity !== null && localPrepItem.quantity > 0
                    ? getQtyPrepItem(prepItem, item)
                    : 1;

                localPrepItem['amount'] = amount;
                return localPrepItem;
              });
            }

            let type = '';
            let subProduct = [];

            if (item.is_package || item.type === 'package') {
              type = 'package';
              subProduct = item.sub_product;
            } else if (item.is_variant) {
              type = 'variant';
            } else {
              type = 'product';
            }

            const newData = {
              _id: _id,
              plu_number: pluNumber,
              plu_name: productName,
              quantity: item.quantity,
              note: item.additional_request || [],
              prep_items: selectedPrepItem,
              type,
              sub_product: subProduct,
              taxes: [],
              signature_token: item.signature_token,
            };

            if (orderMethod === 'waiter_order') {
              const amount = getPriceBySellBand(item, getSellBandId());
              newData['amount'] = amount;
              newData['sales_name'] = getCurrentSalesType().title.toString();
            }

            return newData;
          }),
        };

        // 2. SETELAH DICEK, KIRIM TRANSAKSI SESUAI DENGAN DETAIL DITAHAP 1
        if (orderMethod === 'normal_order' || orderMethod === 'waiter_order') {
          delete data._id; // change untuk mengubah setiap transaksi == transaksi baru

          const isMiniAppCheck = getSetting().miniAppCheck;
          if (isMiniAppCheck) {
            const miniappAvailable = await checkMiniAppConnection(
              '',
              'confirm',
            );
            if (!miniappAvailable) {
              return;
            }
          }
          let result = await hitRealmTransaction(data);
          if (result?.errorMessage) {
            //cari transaksi apakah sudah masuk atau belum. kalo sudah masuk seperti normal,
            //kalo transaksi tidak masuk, munculkan error

            handleCloseConfirmModal();
            const lastTransaction = await isLastTransactionExist({
              table_id: outletInfo.tableId,
              type_bill: orderMethod,
            });

            if (lastTransaction) {
              clearCart();
              const response = { status: 'success' };
              return JSON.stringify(response);
            } else {
              openErrorSnackBar(t('transactionFailed'));
              return true;
            }

            // history.push(URLS.VIEW_BILL);
          }

          if (result) {
            clearCart();
            const response = { status: 'success' };
            return JSON.stringify(response);
          } else {
            history.replace(URLS.INVALID_TOKEN);
            const response = { status: 'error' };
            return JSON.stringify(response);
          }
        }
        if (orderMethod === 'payment_order') {
          //is_view_bill set true sebagai tanda jika sudah masuk ke viewbill
          //digunakan untuk handle qty prepitem setelah balik dari viewbill
          const billId = localStorage.getItem(localStorageNames.VIEW_BILL_ID);
          if (billId !== null && billId !== '') {
            data._id = billId;
          }
          localStorage.setItem(localStorageNames.IS_VIEW_BILL, true);
          let result = await hitRealmTransaction2(data);

          if (result) {
            localStorage.setItem(localStorageNames.VIEW_BILL_ID, result);
            history.push(URLS.VIEW_BILL);
          } else {
            history.replace(URLS.INVALID_TOKEN);
          }
        }
        if (orderMethod === 'quick_order') {
          const isMiniAppCheck = getSetting().miniAppCheck;
          if (isMiniAppCheck) {
            const miniappAvailable = await checkMiniAppConnection(
              tokenValidation.result.parameter,
              'confirm',
            );
            if (!miniappAvailable) {
              return;
            }
          }
          data = { ...data, parameter: tokenValidation.result.parameter };

          let result = await hitRealmTransaction(data);

          if (result?.errorMessage) {
            openErrorSnackBar(t('transactionFailed'));
            return true;
          }

          if (result) {
            clearCart();
            const response = {
              status: 'success',
              parameter: tokenValidation.result.parameter,
            };
            return JSON.stringify(response);
          } else {
            history.replace(URLS.INVALID_TOKEN);
            const response = { status: 'error' };
            return JSON.stringify(response);
          }
        }
      } else {
        if (tokenValidation.status === 401) {
          history.replace(URLS.INVALID_TOKEN);
        } else {
          if (tokenValidation.messages && tokenValidation.messages.length > 0) {
            // JIKA DATA NOT FOUND, MAKA KELUARKAN POP UP DAN FETCH PRODUK TERBARU
            if (tokenValidation.messages[0].includes('data not found')) {
              let productName = tokenValidation.messages[0].split('"');
              openErrorSnackBar(
                t('itemNotFound', {
                  product: productName[1],
                }),
              );
              fetchSplashConfig(getCredential());
            } else {
              let message;
              tokenValidation.messages.forEach((data) => {
                message = data + ' \n';
              });

              if (message.trim() !== 'Failed to fetch') {
                openErrorSnackBar(message);
              }
            }
          } else {
            openErrorSnackBar('Error something');
          }
        }
        const response = { status: 'error' };
        return JSON.stringify(response);
      }
    } else {
      openErrorSnackBar('Error something');
      const response = { status: 'error' };
      return JSON.stringify(response);
    }

    // TOKEN MASIH VALID

    if (orderMethod === 'payment_order') showLoading(false);
  };

  const getUrlCheckout = (data) => {
    const actions = data.actions;
    switch (data.channel_code) {
      case ID_LINKAJA:
        return actions.mobile_web_checkout_url;
      case ID_SHOPEEPAY:
        return actions.mobile_deeplink_checkout_url;
      case ID_DANA:
        return actions.mobile_web_checkout_url;
      default:
    }
  };

  const createChargeEWallet = async () => {
    const SEND_CHARGE_EWALLET =
      process.env[
        `REACT_APP_CREATE_EWALLET_CHARGES_${process.env.REACT_APP_ENV}`
      ];
    handleCloseConfirmPayment();
    setContextState({ loadingPayment: true });

    const response = await hitAPI(
      SEND_CHARGE_EWALLET,
      'POST',
      getCredential(),
      getContextState('confirmPayment').data,
    );

    if (response !== undefined && response !== null) {
      if (response.status === 200) {
        const data = response.result.data;
        const chargeAmount = parseFloat(data.charge_amount).toFixed(0);
        const captureAmount = parseFloat(data.capture_amount).toFixed(0);
        const subFunctionId = response.result.SubFunctionID;
        const newDataPayment = {
          ...response.result.data,
          charge_amount: chargeAmount,
          capture_amount: captureAmount,
        };

        handleCloseCheckoutPage();
        clearOrderCart();

        const transId = localStorage.getItem(localStorageNames.VIEW_BILL_ID);
        const result = await sendClientPayment(
          newDataPayment,
          transId,
          subFunctionId,
        );
        if (result) {
          //Selain pembayaran menggunakan ovo akan menerima url untuk checkout pembayaran
          //jika pembayaran menggunakan OVO akan mendapatkan notif di aplikasi

          localStorage.removeItem(localStorageNames.IS_VIEW_BILL);
          localStorage.removeItem(localStorageNames.VIEW_BILL_ID);

          if (data.channel_code !== ID_OVO) {
            await window.open(getUrlCheckout(data), '_self');
          }
          setTimeout(() => {
            setContextState({ loadingPayment: false });
            history.replace(URLS.MENU_CATALOGUE);
          }, 1500);
        }
        return JSON.stringify({ status: 'success' });
      } else {
        setContextState({ loadingPayment: false });

        let messages = '';
        if (response.messages && Array.isArray(response.messages)) {
          response.messages.map((data) => {
            messages += data + '\n';
            return null;
          });
        } else {
          messages = response.messages;
        }
        openErrorSnackBar(messages);
        return JSON.stringify({ status: 'error' });
      }
    } else {
      history.replace(URLS.SESSION_EXPIRED);
    }
  };

  const clearCart = () => {
    setCartItems([]);
  };

  const clearOrderCart = () => {
    localStorage.removeItem(localStorageNames.IS_VIEW_BILL);
    clearCart();
  };

  const isChooseRewards = () => {
    let parentItems = getCartItems().filter((x) => x.promoFrom === undefined);
    const lastParentItem = parentItems[parentItems.length - 1];
    let rewardItems = getCartItems().filter(
      (x) => x.promoFrom === lastParentItem.timestamp,
    );
    if (lastParentItem.quantity === rewardItems.length) {
      return true;
    } else {
      return false;
    }
  };

  const handleOpenCheckoutPage = () => {
    if (getContextState('isRewardsPage')) {
      if (isChooseRewards()) {
        setContextState({ isRewardsPage: false });
        setCheckoutPage(true);
      } else {
        setContextState({ confirmExitRewards: true });
      }
    } else if (getSuggestionPage()) {
      setSuggestionPage(false);
      setCheckoutPage(true);
    } else {
      setCheckoutPage(true);
    }
  };

  const handleCloseCheckoutPage = () => {
    setCheckoutPage(false);
  };

  const handleOpenConfirmModal = (orderMethod, parameter) => {
    if (checkTokenIsExpired()) {
      history.replace(URLS.SESSION_EXPIRED);
    } else {
      if (parameter) {
        localStorage.setItem(localStorageNames.CUSTOM_PARAMETER, parameter);
      }
      setConfirmModal(true, orderMethod, parameter);
    }
  };

  const handleCloseConfirmModal = () => {
    setConfirmModal(false, null, null);
  };

  const handleCloseCartModal = (param) => {
    if (!getSetting().isDirectOrder && !getCartModalData().isSuggestionPage) {
      const data = getContextState('cartModal').data;
      const suggestion = data.is_have_suggestion;
      if (suggestion && param !== 'close') {
        setSuggestionPage(true);
      }
    }
    setCartModal(false, null);
  };

  const handleCloseQrcodeModal = () => {
    setQrcodeModal(false, null);
  };

  const handleCloseConfirmPayment = () => {
    setConfirmPayment(false);
  };

  useEffect(() => {
    forceReset();
    // CEK LOCALSTORAGE KALAU ADA VALUE CART NYA, MAKA MASUKKAN KEDALAM STATE
    let LSCart = getCartItemLocalStorage();
    if (isLoggedIn() && LSCart && LSCart.length > 0) {
      setCartItems(LSCart);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getCartItems().length === 0) {
      handleCloseCheckoutPage();
    }
    // KETIKA ADA PERUBAHAN DI STATE, UPDATE JUGA LOCALSTORAGENYA
    setCartItemLocalStorage(getCartItems());
    // eslint-disable-next-line
  }, [getContextState('cart').items]);

  return (
    <CartContext.Provider
      value={{
        getContextState,
        setContextState,
        setCartModal,
        setQrcodeModal,
        getCartModalVisibility,
        addToCart,
        setCheckoutPage,
        changeCartItemQuantity,
        clearCart,
        removeItemInCart,
        forceReset,
        handleOpenCheckoutPage,
        createChargeEWallet,
        setConfirmPayment,
        getSuggestionPage,
        setSuggestionPage,
        clearOrderCart,
        getXenditPage,
        insertProductDetail,
        getBadgeCount,
        isLoggedIn,
        totalPrice,
        getCartItems,
      }}>
      {children}

      {getCartModalVisibility() && (
        <ReAddToCartModal
          item={getCartModalData()}
          closeModal={(param) => handleCloseCartModal(param)}
          isSuggestion={getSuggestionPage()}
          dataSuggestion={getContextState('cartModal').data.uuid_product}
          isRewardsPage={getContextState('isRewardsPage')}
        />
      )}

      {getContextState('qrcodeModal').visible && (
        <QrCodeDialog closeModal={() => handleCloseQrcodeModal()} />
      )}

      {getContextState('checkoutPage').visible && (
        <CheckoutPage
          changeItemQty={(item, qty) => changeCartItemQuantity(item, qty)}
          removeItemInCart={(item) => removeItemInCart(item)}
          cartItem={getCartItems()}
          totalPrice={totalPrice()}
          totalQty={totalQty()}
          clearCart={() => clearOrderCart()}
          closeModal={() => handleCloseCheckoutPage()}
          onConfirm={(orderMethod, parameter) =>
            handleOpenConfirmModal(orderMethod, parameter)
          }
          onViewBill={(orderMethod) => sendTransaction(orderMethod, null)}
        />
      )}

      {getContextState('confirmModal').visible && (
        <ConfirmDialog
          titleDialog={getContextState('confirmModal').titleDialog}
          messageDialog={getContextState('confirmModal').messageDialog}
          closeModal={() => handleCloseConfirmModal()}
          confirmTransaction={async () => {
            return await sendTransaction(
              getContextState('confirmModal').orderMethod,
              getContextState('confirmModal').parameter,
            );
          }}
          closeQRcode={() => forceReset()}
          setting={getSetting()}
        />
      )}

      {getContextState('confirmPayment').visible && (
        // confirm dialog pembayaran Xendit
        <ConfirmDialog
          titleDialog={getContextState('confirmPayment').titleDialog}
          messageDialog={getContextState('confirmPayment').messageDialog}
          closeModal={() => handleCloseConfirmPayment()}
          confirmTransaction={async () => {
            return await createChargeEWallet();
          }}
          setting={getSetting()}
        />
      )}
      {getContextState('loadingPayment') && <LoadingPayment />}
    </CartContext.Provider>
  );
};

export const useCartContext = () => {
  const value = useContext(CartContext);
  if (value == null) {
    throw new Error('useCartContext() called outside of a Provider?');
  }
  return value;
};

export default CartProvider;
