import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import ReDialog from 'components/ReDialog';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import localStorageNames from 'data/localStorageNames';
import { useCartContext } from 'context/CartContext';
import { useAuthContext } from 'context/AuthContext';

const QrCodeDialog = ({ closeModal, data = '' }) => {
  const { t } = useTranslation();
  const { getSetting } = useAuthContext();
  const { clearCart } = useCartContext();
  const [open, setOpen] = useState(false);
  const [tableName, setTableName] = useState(null);

  const handleIfCloseTab = () => {
    window.addEventListener('beforeunload', alertUser);
  };

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = '';
  };

  const handleClose = () => {
    setOpen(false);
    closeModal();
  };

  useEffect(() => {
    const setting = JSON.parse(localStorage.getItem(localStorageNames.SETTING));
    if (setting.tableName) {
      setTableName(setting.tableName);
    }
  }, []);

  useEffect(() => {
    handleIfCloseTab();
    clearCart();

    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
    // eslint-disable-next-line
  }, []);

  const AlertDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Are you sure you want to leave this page?'}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="success">
            Cancel
          </Button>
          <Button onClick={handleClose} color="success" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <ReDialog
      fullScreen={true}
      closeModal={closeModal}
      useCloseButton={false}
      contentStyle={{ paddingTop: theme.spacing(-10) }}
      content={
        <Box
          display="flex"
          flex="4"
          flexDirection="column"
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '90vh',
          }}>
          <AlertDialog />
          <Prompt
            when={true}
            message={(location, action) => {
              if (action === 'POP') {
                setOpen(true);
                return false;
              }
            }}
          />
          <Box
            display="flex"
            flexDirection="column"
            style={{ justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant="h5" style={{ marginBottom: theme.spacing(4) }}>
              {tableName && `TABLE : ${tableName}`}
            </Typography>
            <Typography variant="subtitle1" align="center">
              {
                (getSetting() && getSetting().qrcodeTitlePage) ?
                  getSetting().qrcodeTitlePage :
                  t('qrcodeTitle')
              }
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              style={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}>
              {t('qrcodeSubTitle')}
            </Typography>
            <QRCode value={data} size="300" />
            <Typography variant="h5" style={{ marginTop: theme.spacing(4) }}>
              {data}
            </Typography>
          </Box>
        </Box>
      }
    />
  );
};

export default QrCodeDialog;
