import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {
  getBackgroundColor,
  getPrimaryFontColor,
  getSecondaryFontColor,
  getSecondaryThemeColor,
  getPrimaryThemeColor,
} from 'functions/ThemeSettings';

const fastSolution = (parameter) => {
  return new URLSearchParams(window.location.href).get(parameter);
};

let primaryThemeColor =  getPrimaryThemeColor();
let secondaryThemeColor = getSecondaryThemeColor();
let primaryFontColor = getPrimaryFontColor();
let secondaryFontColor =  getSecondaryFontColor();
let backgroundColor = fastSolution('background') || getBackgroundColor();

let theme = createMuiTheme({
  palette: {
    primary: {
      main: primaryThemeColor,
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: secondaryThemeColor,
      contrastText: '#000000',
    },
    error: {
      main: '#B00020',
    },
    warning: {
      main: '#E2CF21',
    },
    info: {
      main: '#0096C7',
    },
    success: {
      main: '#02A328',
    },
    text: {
      primary: primaryFontColor,
      secondary: secondaryFontColor,
      disabled: '#C7C7C7',
    },
  },
  typography: {
    fontFamily: ['"Helvetica Neue"'].join(','),
  },
  spacing: 4,
  containerWidth: 450,
  siteBackgroundColor: backgroundColor,
});

theme = responsiveFontSizes(theme);

export default theme;
