import React, { useEffect, useRef } from 'react';
import './App.css';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { BrowserRouter as Router } from 'react-router-dom';
import GeneralProvider from 'context/GeneralContext';
import Routes from './Routes.js';
import theme from 'styles/theme';
import packageJson from '../package.json';
import BrowserNotSupported from 'pages/BrowserNotSupported';
import * as Sentry from '@sentry/browser';
import localStorageNames from 'data/localStorageNames';

let willMount = true;

function App() {
  const _refApp = useRef();
  let validBrowser = true;
  var longURL;

  const checkBrowserVersion = () => {
    let minimalChromeVersion = 73;
    // TODO
    // let minimalSafariVersion = 0;
    let browserInfo = navigator.userAgent;
    let blockedBrowser = false;

    // ANDROID
    // if (/Android/i.test(browserInfo)) {
    //   let chromeVersion = /Chrome\/(\d+)/.exec(browserInfo)[1];
    //   if (chromeVersion < minimalChromeVersion) {
    //     blockedBrowser = true;
    //   }
    // }

    // IOS
    // if (/iPad|iPhone|iPod/.test(browserInfo) && !window.MSStream) {
    //   // TODO
    //   let safariVersion = /Safari\/(\d+)/.exec(browserInfo)[1];
    //   if (safariVersion < minimalSafariVersion) {
    //     blockedBrowser = true;
    //   }
    // }

    let chromeVersion = /Chrome\/(\d+)/.exec(browserInfo);
    if (chromeVersion && chromeVersion[1] < minimalChromeVersion) {
      blockedBrowser = true;
    }

    if (blockedBrowser) {
      // tidak bisa pakai path url React (/browser-not-supported, etc)
      // karena diluar scope Routes
      // sedangkan diinject ke Routes pun juga error, karena belum fetch config, error theme dependency ke response config
      // MVP pakai if else dengan raw react js biasa

      validBrowser = false;
    }
  };

  const checkObjectFromEntries = () => {
    try {
      Object.fromEntries([]);
    } catch (error) {
      validBrowser = false;
    }
  };

  const initContextSentry = () => {
    var token = new URLSearchParams(window.location.search).get('token');
    if (window.location.search && token) {
      longURL = decodeURIComponent(window.location.href);
      localStorage.setItem(localStorageNames.LONG_URL, longURL);
    } else {
      longURL = localStorage.getItem(localStorageNames.LONG_URL);
    }
    Sentry.setContext('URL', {
      Long_URL: longURL,
    });
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENV !== 'PRODUCTION') {
      document.title = `${process.env.REACT_APP_ENV} - ${document.title}`;
      console.log(`environment : ${process.env.REACT_APP_ENV}`);
      console.log(
        `cdn path : ${
          process.env[`REACT_APP_CDN_URL_${process.env.REACT_APP_ENV}`]
        }`,
      );
      console.log(`version code : ${packageJson.version}`);
    }
    initContextSentry();
    // eslint-disable-next-line
    willMount = false;
  }, []);

  if (willMount) {
    checkBrowserVersion();
    checkObjectFromEntries();
  }

  return (
    <div className="App" ref={_refApp}>
      {validBrowser ? (
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <GeneralProvider>
              <Routes />
            </GeneralProvider>
          </Router>
        </ThemeProvider>
      ) : (
        <BrowserNotSupported />
      )}
    </div>
  );
}

export default App;
