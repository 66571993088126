import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import {
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Checkbox,
  Box,
} from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import theme from 'styles/theme';
import { convertCurrency } from 'functions/convertNumber';
import { useTranslation } from 'react-i18next';
import ReButtonQuantity from './ReButtonQuantity';
import { getPriceBySellBand } from 'functions/getItemPriceBySellBand';
import { useGeneralContext } from 'context/GeneralContext';
import { getColorApp } from 'data/localStorageGetter';
import { useAuthContext } from 'context/AuthContext';

const useStyles = makeStyles((theme) => ({
  accordionIdle: {
    color: getColorApp().primaryFontColor,
  },
  accordionActive: {
    color: getColorApp().primaryFontColor,
  },
  accordionSuccess: {
    color: getColorApp().secondaryFontColor,
  },
  expandIcon: {
    color: 'inherit',
  },
  headingTitle: {
    height: 48,
    minHeight: `48px !important`,
  },
  headingContent: {
    width: '80%',
  },
  textWrapper: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  accordionDetail: {
    backgroundColor: getColorApp().backgroundAccordion,
    color: getColorApp().primaryFontColor,
  },
  widthButtonQty: {
    width: 125,
    alignSelf: 'center',
  },
  widthCurrency: {
    width: 160,
    marginLeft: theme.spacing(2),
  },
  borderCheckbox: {
    padding: 0,
    marginRight: theme.spacing(1),
  },
}));

const Accordion = withStyles({
  root: {
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const OptionalItem = ({
  qtyProduct,
  prepItem,
  checkItem,
  changeQuantity,
  max,
  isOrderable,
  isModifier,
  getSetting,
  t
}) => {
  const classes = useStyles();
  const { getSellBandId } = useGeneralContext();

  const disableLogic = (prepItem, item) => {
    let checkedItem = prepItem.filter((x) => x.checked);

    let totalQtySelected = 0;
    for (let j = 0; j < checkedItem.length; j++) {
      totalQtySelected += checkedItem[j].quantity ? checkedItem[j].quantity : 1;
    }
    if (item.checked) {
      return false;
    } else if (!item.checked && totalQtySelected >= max) {
      return true;
    }
  };

  const actionQtyButton = (index, value) => {
    changeQuantity(index, value);
  };

  const actionChecboxClick = (index) => {
    checkItem(index);
    if (!isModifier) {
      changeQuantity(index, 1);
    }
  };

  const countOtherPrep = (prepItem, uuid_product) => {
    let checkedItem = prepItem.filter(
      (x) => x.checked && x.uuid_product !== uuid_product,
    );
    let totalQtySelected = 0;
    for (let j = 0; j < checkedItem.length; j++) {
      totalQtySelected += checkedItem[j].quantity ? checkedItem[j].quantity : 1;
    }
    return totalQtySelected;
  };

  return (
    <>
      {Array.isArray(prepItem) &&
        prepItem.map((item, index) => (
          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            style={{ margin: `${theme.spacing(1)}px 0` }}>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              style={{ width: 'inherit' }}>
              <Box
                display="flex"
                flexDirection="row"
                onClick={() =>
                  isOrderable &&
                  (!item.uuid_product_modifier ? item.is_active : item.uuid_product_modifier)  &&
                  !disableLogic(prepItem, item) &&
                  actionChecboxClick(index, item.quantity)
                }
                style={{ justifyContent: 'start' }}>
                {isOrderable && (
                  <Checkbox
                    checked={item.checked || false}
                    disabled={disableLogic(prepItem, item)}
                    className={classes.borderCheckbox}
                    style={{ color: (!item.uuid_product_modifier ? item.is_active : item.uuid_product_modifier) ? getColorApp().successAccordion : '#00000042'}}
                  />
                )}
                <Box
                  component="div"
                  whiteSpace="normal"
                  alignSelf="center"
                  style={{ marginRight: theme.spacing(1) }}>
                  {isModifier ? item.message : item.plu_name}
                </Box>
              </Box>
            </Box>
            {item.checked && item.use_quantity && (
              <Box className={classes.widthButtonQty}>
                <ReButtonQuantity
                  onChangeValue={(value) => actionQtyButton(index, value)}
                  max={max - countOtherPrep(prepItem, item.uuid_product) - 1}
                  qtyProduct={qtyProduct}
                />
              </Box>
            )}
            <Box
              display="flex"
              alignSelf="center"
              justifyContent="flex-end"
              className={classes.widthCurrency}>
              {(getPriceBySellBand(item, getSellBandId()) !== 0 || (!item.is_active && !item.uuid_product_modifier))  && (
                <Typography variant="caption" className={classes.textWrapper}>
                  {item.is_active
                    ? convertCurrency(getPriceBySellBand(item, getSellBandId()))
                    : getSetting() && getSetting().soldOutText
                      ? getSetting().soldOutText
                      : t('outOfStock')}
                </Typography>
              )}
            </Box>
          </Box>
        ))}
    </>
  );
};

const RePrepItemAccordion = ({
  qtyProduct,
  prepGroup,
  checkItem,
  changeQuantity,
  isOrderable,
  isModifier,
}) => {
  const classes = useStyles();
  const { getSetting } = useAuthContext();
  const [expandedPanel, setExpandedPanel] = useState(0);
  const { t } = useTranslation();

  const handleExpand = (event, index) => {
    setExpandedPanel(index === expandedPanel ? null : index);
  };

  const accordionStyle = (index, prepItem = [], min) => {
    let checkedItem = prepItem.filter((x) => x.checked);
    let qtySelected = 0;
    for (let j = 0; j < checkedItem.length; j++) {
      qtySelected += checkedItem[j].quantity ? checkedItem[j].quantity : 1;
    }

    let background = {
      backgroundColor: getColorApp().headerAccordion,
      color: getColorApp().primaryFontColor,
    };

    if (qtySelected >= min) {
      background = {
        backgroundColor: getColorApp().successAccordion,
        color: getColorApp().secondaryFontColor,
      };
    }

    return background;
  };

  return (
    <Grid>
      {Array.isArray(prepGroup) &&
        prepGroup.map((group, index) => (
          <Box>
            {getSetting().isProductOptionFold ? (
              <Accordion
                key={group.uuid_group}
                style={{
                  ...accordionStyle(
                    index,
                    isModifier ? group.modifiers : group.prep_item,
                    group.choose.min,
                  ),
                }}
                defaultExpanded={true}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  classes={{
                    expandIcon: classes.expandIcon,
                    root: classes.headingTitle,
                    content: classes.headingContent,
                  }}>
                  <Typography variant="subtitle2" className={classes.textWrapper}>
                    {group.group_name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetail}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">
                        {`${t('minChoose')} = ${group.choose.min}, ${t(
                          'maxChoose',
                        )} = ${group.choose.max} ${!isModifier ? `(${t('perproduct')})` : ``
                          }`}
                      </Typography>
                    </Grid>
                    {isModifier &&
                      group.modifiers &&
                      group.modifiers.length > 0 && (
                        <OptionalItem
                          qtyProduct={qtyProduct}
                          prepItem={group.modifiers}
                          checkItem={(itemIndex) => checkItem(index, itemIndex)}
                          max={group.choose.max}
                          isOrderable={isOrderable}
                          isModifier={isModifier}
                          getColorApp={getColorApp}
                          getSetting={getSetting}
                          t={t}
                        />
                      )}
                    {!isModifier &&
                      group.prep_item &&
                      group.prep_item.length > 0 && (
                        <OptionalItem
                          qtyProduct={qtyProduct}
                          prepItem={group.prep_item}
                          checkItem={(itemIndex) => checkItem(index, itemIndex)}
                          changeQuantity={(itemIndex, qty) =>
                            changeQuantity(index, itemIndex, qty)
                          }
                          max={isModifier ? 0 : group.choose.max}
                          isOrderable={isOrderable}
                          getColorApp={getColorApp}
                          getSetting={getSetting}
                          t={t}
                        />
                      )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            ) : (
              <Accordion
                key={group.uuid_group}
                style={{
                  ...accordionStyle(
                    index,
                    isModifier ? group.modifiers : group.prep_item,
                    group.choose.min,
                  ),
                }}
                expanded={expandedPanel === index}
                onChange={(event) => {
                  handleExpand(event, index);
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  classes={{
                    expandIcon: classes.expandIcon,
                    root: classes.headingTitle,
                    content: classes.headingContent,
                  }}>
                  <Typography variant="subtitle2" className={classes.textWrapper}>
                    {group.group_name}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.accordionDetail}>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography variant="caption">
                        {`${t('minChoose')} = ${group.choose.min}, ${t(
                          'maxChoose',
                        )} = ${group.choose.max} ${!isModifier ? `(${t('perproduct')})` : ``
                          }`}
                      </Typography>
                    </Grid>
                    {isModifier &&
                      group.modifiers &&
                      group.modifiers.length > 0 && (
                        <OptionalItem
                          qtyProduct={qtyProduct}
                          prepItem={group.modifiers}
                          checkItem={(itemIndex) => checkItem(index, itemIndex)}
                          max={group.choose.max}
                          isOrderable={isOrderable}
                          isModifier={isModifier}
                          getColorApp={getColorApp}
                          getSetting={getSetting}
                          t={t}
                        />
                      )}
                    {!isModifier &&
                      group.prep_item &&
                      group.prep_item.length > 0 && (
                        <OptionalItem
                          qtyProduct={qtyProduct}
                          prepItem={group.prep_item}
                          checkItem={(itemIndex) => checkItem(index, itemIndex)}
                          changeQuantity={(itemIndex, qty) =>
                            changeQuantity(index, itemIndex, qty)
                          }
                          max={isModifier ? 0 : group.choose.max}
                          isOrderable={isOrderable}
                          getColorApp={getColorApp}
                          getSetting={getSetting}
                          t={t}
                        />
                      )}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            )}
          </Box>
        ))}
    </Grid>
  );
};

export default RePrepItemAccordion;
