import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  Divider,
  Typography,
  Grid,
  Box,
  GridList,
  ListItemText,
  Accordion,
  AccordionSummary,
} from '@material-ui/core';
import { GTranslate } from '@material-ui/icons';
import ReDropdownLang from 'components/ReDropdownLang';
import { useGeneralContext } from 'context/GeneralContext';
import { useAuthContext } from 'context/AuthContext';
import ReFooter from 'components/ReFooter';
import { makeStyles } from '@material-ui/core/styles';
import localStorageNames from 'data/localStorageNames';
import theme from 'styles/theme';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getDefaultLogo } from 'functions/getImageUrlPath';
import packageJson from '../../../package.json';
import URLS from 'URLS';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { scroller } from 'react-scroll';
import { getColorApp, getLogoUrl } from 'data/localStorageGetter';

const useStyles = makeStyles({
  paper: {
    backgroundColor: (props) => {
      if (props) {
        return props?.backgroundColor;
      }
      return theme.palette.primary.contrastText;
    },
    maxWidth: '80%',
    overflow: 'hidden',
  },
  paperBigScreen: {
    backgroundColor: (props) => {
      if (props) {
        return props?.backgroundColor;
      }
      return theme.palette.primary.contrastText;
    },
    left: `calc((100% - ${theme.containerWidth}px) / 2)`,
    maxWidth: '80%',
    overflow: 'hidden',
  },
  nested: {
    paddingLeft: theme.spacing(8),
    cursor: 'pointer',
  },
  logo: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
  },
  keterangan: {
    margin: `${theme.spacing(2)}px 0`,
    textAlign: 'center',
  },
  panel: {
    boxShadow: 'none',
    backgroundColor: 'transparent',
    margin: theme.spacing(0),
  },
  panelSummary: {
    padding: `0 ${theme.spacing(4)}px 0 0`,
    height: theme.spacing(1),
  },
});

const ToggleDrawer = ({ isBigScreen, tableName }) => {
  const classes = useStyles(getColorApp());
  const { t } = useTranslation();
  const { getContextState, closeDrawer, getLocalStorage } = useGeneralContext();
  const {
    getCategoryList,
    getSetting,
    onClickTabCategory,
    getSpreadCategories,
  } = useAuthContext();
  const history = useHistory();

  const getCredential = () => {
    return getLocalStorage(localStorageNames.USER) === 'guest'
      ? t('guest')
      : getLocalStorage(localStorageNames.USER);
  };

  // const logout = () => {
  //   clearAllStorage();
  //   setDrawerVisible(false);
  //   window.location.href =
  //     process.env[`REACT_APP_QR_URL_${process.env.REACT_APP_ENV}`];
  // };

  const getCategoryIndex = (uuid) => {
    let index = -1;
    let categories = getSpreadCategories();
    if (Array.isArray(categories) && categories.length > 0) {
      for (let i = 0; i < categories.length; i++) {
        if (categories[i].uuid_product_category === uuid) {
          index = i;
          break;
        }
      }
    }
    return index;
  };

  const handleItemClick = (category, uuid) => {
    if (getSetting().isInfinityScroll) {
      scroller.scrollTo(uuid, {
        duration: 200,
        delay: 0,
        isDynamic: true,
        containerId: 'containerElementScroll',
      });
      closeDrawer();
    } else {
      onClickTabCategory(category, getCategoryIndex(uuid));
      closeDrawer();
    }
  };

  const getLogoImage = () => {
    if (getLogoUrl()) return getLogoUrl();
    return getDefaultLogo();
  };

  return (
    <Drawer
      open={getContextState('drawer')}
      anchor="left"
      onClose={() => closeDrawer()}
      transitionDuration={200}
      classes={{ paper: isBigScreen ? classes.paperBigScreen : classes.paper }}>
      <Box display="flex" flexDirection="column" height="94vh">
        <GridList cellHeight={'auto'}>
          <List style={{ width: '100%', padding: 0 }}>
            <Grid item className={classes.logo}>
              <img alt="logo" src={getLogoImage()} style={{ height: 100 }} />
            </Grid>
            <Box className={classes.keterangan}>
              <Typography variant="body1">{t('loggedin')}</Typography>
              <Typography variant="body1">{getCredential()}</Typography>
            </Box>
            <Divider />
            {getSetting() && getSetting().isStore !== true && (
              <>
                <Box className={classes.keterangan}>
                  <Typography variant="body1">{t('table')}</Typography>
                  <Typography variant="body1" className={'textwrapper'}>
                    {tableName}
                  </Typography>
                </Box>
                <Divider />
              </>
            )}
            {getSetting() &&
              getSetting().isShowViewBill &&
              (getSetting().orderMethod === 'normal_order' ||
                getSetting().orderMethod === 'waiter_order') && (
                <>
                  <ListItem
                    button
                    onClick={() => {
                      closeDrawer();
                      history.push(URLS.VIEW_BILL);
                    }}>
                    {t('viewBill').toUpperCase()}
                  </ListItem>
                  <Divider />
                </>
              )}
            {getSetting() && getSetting().orderMethod === 'payment_order' && (
              <>
                <ListItem
                  button
                  onClick={() => {
                    closeDrawer();
                    history.push(URLS.VIEW_TRANSACTION);
                  }}>
                  {t('viewTransaction').toUpperCase()}
                </ListItem>
                <Divider />
              </>
            )}
            {(getCategoryList() || []).map((category) => (
              <div>
                <Accordion className={classes.panel}>
                  <AccordionSummary
                    className={classes.panelSummary}
                    expandIcon={
                      category?.categories?.length > 0 && (
                        <ExpandMoreIcon style={{ color: 'black' }} />
                      )
                    }>
                    <ListItem
                      onClick={() => {
                        if (category?.categories?.length < 1) {
                          handleItemClick(
                            category,
                            category.uuid_product_category,
                          );
                        }
                      }}>
                      <ListItemText>
                        {category.category_name.toUpperCase()}
                      </ListItemText>
                    </ListItem>
                  </AccordionSummary>
                  {Array.isArray(category.categories) &&
                    category.categories.map((subCategory) => (
                      <ListItem
                        className={classes.nested}
                        onClick={() =>
                          handleItemClick(
                            subCategory,
                            subCategory.uuid_product_category,
                          )
                        }>
                        {subCategory.category_name.toUpperCase()}
                      </ListItem>
                    ))}
                </Accordion>
              </div>
            ))}
            <Divider />
            <ListItem
              button
              onClick={() => {
                closeDrawer();
                history.push(URLS.FEEDBACK);
              }}>
              {getSetting() && getSetting().feedbackText
                ? getSetting().feedbackText
                : t('feedback').toUpperCase()}
            </ListItem>
            <Divider />
            <ListItem>
              <Grid
                container
                direction="row"
                style={{ justifyContent: 'space-between' }}>
                <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                  <GTranslate
                    style={{ marginRight: theme.spacing(1) }}
                    fontSize="small"
                  />{' '}
                  {t('language')}
                </Grid>
                <Grid item>
                  <ReDropdownLang id="DropdownLang:ToggleDrawer" />
                </Grid>
              </Grid>
            </ListItem>
            {/* <ListItem button onClick={() => logout()}>
              <ExitToApp style={{ marginRight: theme.spacing(1) }} /> {t('logout')}
            </ListItem> */}
          </List>
        </GridList>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignContent="center"
        height="6vh">
        <ReFooter textColor={getColorApp().primaryFontColor} />
        <Box textAlign="center" fontSize={10}>
          V {packageJson.version}
        </Box>
      </Box>
    </Drawer>
  );
};

export default ToggleDrawer;
