import React, { useState, useEffect, useRef } from 'react';
import { Typography, Grid, TextField, Box, Button } from '@material-ui/core';
import ReDialog from 'components/ReDialog';
import RePackageItem from 'components/RePackageItem';
import ReButtonQuantity from 'components/ReButtonQuantity';
import {
  makeStyles,
} from '@material-ui/core/styles';
import { AddShoppingCart } from '@material-ui/icons';
import { convertCurrency } from 'functions/convertNumber';
import { sumTotalPriceWithPrepItem } from 'functions/sumTotalPriceWithPrepItem';
import { useGeneralContext } from 'context/GeneralContext';
import { useCartContext } from 'context/CartContext';
import { useTranslation } from 'react-i18next';
import theme from 'styles/theme';
import { useAuthContext } from 'context/AuthContext';
import ReLazyImage from './ReLazyImage';
import RePrepItemAccordion from './RePrepItemAccordion';
import ReVariantAccordion from './ReVariantAccordion';
import { getImageFullPage } from 'functions/imageOptimization';
import { getColorApp } from 'data/localStorageGetter';

const useStyles = makeStyles({
  additionalRequest: {
    backgroundColor: '#FFFFFF',
    width: '100%',
    '& .MuiInputLabel-root': {
      color: props => props.primaryFontColor
    },
    '& ::placeholder': {
      color: theme.palette.text.disabled,
    },
    '& label.Mui-focused': {
      color: props => props.primaryFontColor
    },
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: props => props.primaryFontColor
      },
    },
  },
  priceAndQtyContainer: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  buttonQty: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  buttonAddToCart: {
    marginTop: 20,
    width: '80%',
    left: `calc(50% - 80% / 2)`,
    color: props => props.primaryFontColor,
    backgroundColor: props => props.addButton,
    '&:hover': {
      backgroundColor: props => props.addButton,
    },
  },
  buttonImage: {
    borderRadius: theme.spacing(1),
    borderWidth: 'thin',
    borderStyle: 'solid',
    marginBottom: theme.spacing(1),
  },
  marginTopBottom: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  marginLeftRight: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
});

const ReAddToCartModal = ({
  item,
  closeModal,
  isSuggestion,
  dataSuggestion,
  isRewardsPage,
}) => {
  const classes = useStyles(getColorApp());
  const { openSuccessSnackBar, getSellBandId } = useGeneralContext();
  const { addToCart } = useCartContext();
  const { getSetting } = useAuthContext();
  const { t } = useTranslation();
  const noderef = useRef();
  const [localState, setLocalState] = useState({
    qty: 1,
    additional_request: '',
    selectedItem: {},
    isShowImage: false,
    indexVariantSelected: -1,
  });

  useEffect(() => {
    // STRINGIFY THEN PARSE, BECAUSE DEEP NESTED OBJECT CANT BE "SPREAD" AS USUAL,
    // SPREAD ONLY WORK ONLY FOR 1st LEVEL OF OBJECT (NOT NESTED OBJECT)
    let reConstructItem = JSON.stringify({ ...item });
    reConstructItem = JSON.parse(reConstructItem);
    updateLocalState({ selectedItem: { ...reConstructItem } });
    // eslint-disable-next-line
  }, []);

  const updateLocalState = (newData) => {
    setLocalState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getLocalState = (key) => {
    return key ? localState[key] : localState;
  };

  const handleChangeQuantity = (value) => {
    updateLocalState({
      qty: value,
    });
  };

  const mergeModifierWithAdditionalRequest = (
    additionalRequest,
    modifierGroup = [],
  ) => {
    let newAdditionalRequest = [];
    newAdditionalRequest = modifierGroup.reduce((acc, value) => {
      const modifiers = value.modifiers.filter((v) => v.checked);
      return [...acc, ...modifiers.map((v) => v.message)];
    }, []);
    if (additionalRequest) {
      newAdditionalRequest.push(additionalRequest);
    }
    return newAdditionalRequest;
  };

  const handleAddToCart = () => {
    getLocalState('selectedItem').quantity = getLocalState('qty');

    let modifierGroup = [];
    if (getLocalState('selectedItem').is_variant) {
      var variantSelected = getLocalState('selectedItem').variant_item.filter(
        (item) => item.checked,
      );

      modifierGroup = variantSelected[0].modifier_group;
    } else {
      modifierGroup = getLocalState('selectedItem').modifier_group;
    }

    getLocalState('selectedItem').additional_request =
      mergeModifierWithAdditionalRequest(
        document.getElementById('addtional')
          ? document.getElementById('addtional').value
          : null,
        modifierGroup,
      );
    delete getLocalState('selectedItem').modifier_group;
    getLocalState('selectedItem').timestamp = new Date().getTime();
    addToCart(getLocalState('selectedItem'));

    closeModal();
    openSuccessSnackBar(
      `${getLocalState('qty')} x "${
        getLocalState('selectedItem').plu_name
      }" ${t('hasBeenAddedToCart')}`,
    );
  };

  const handleCheckVariant = (groupIndex, itemIndex) => {
    let localItem = { ...getLocalState('selectedItem') };
    localItem.variant_item.map((item, index) => {
      return parseInt(itemIndex) === index
        ? (item.checked = true)
        : (item.checked = false);
    });
    updateLocalState({
      indexVariantSelected: itemIndex,
      selectedItem: localItem,
    });
  };

  const getSpecificItem = (localItem) => {
    return localItem.is_variant
      ? localItem.variant_item.find((item) => item.checked)
      : localItem;
  };

  const handleCheckModifier = (groupIndex, itemIndex) => {
    let localItem = { ...getLocalState('selectedItem') };
    let item = getSpecificItem(localItem); // check dulu apakah variant, lalu get item nya

    if (item) {
      let currentCheckValue =
        item.modifier_group[groupIndex].modifiers[itemIndex].checked;

      item.modifier_group[groupIndex].modifiers[itemIndex].checked =
        !currentCheckValue ? true : !currentCheckValue;
    }
    updateLocalState({ selectedItem: localItem });
  };

  const handleCheckPrepItem = (groupIndex, itemIndex) => {
    let localItem = { ...getLocalState('selectedItem') };
    let item = getSpecificItem(localItem); // check dulu apakah variant, lalu get item nya

    if (item) {
      let currentCheckValue =
        item.prep_group[groupIndex].prep_item[itemIndex].checked;

      item.prep_group[groupIndex].prep_item[itemIndex].checked =
        !currentCheckValue ? true : !currentCheckValue;
    }
    updateLocalState({ selectedItem: localItem });
  };

  const handleChangePrepQuantity = (groupIndex, itemIndex, qty) => {
    let localItem = { ...getLocalState('selectedItem') };
    let item = getSpecificItem(localItem); // check dulu apakah variant, lalu get item nya

    if (item) {
      item.prep_group[groupIndex].prep_item[itemIndex].quantity = qty;
    }
    updateLocalState({ selectedItem: localItem });
  };

  const disabledButton = () => {
    let localItem = getLocalState('selectedItem');
    let item = getSpecificItem(localItem); // check dulu apakah variant, lalu get item nya
    let notFullFilled = false;

    if (item) {
      let hasPrepGroup = item.prep_group && item.prep_group.length > 0;

      //Cek jika item yang dipilih memiliki prep group & prep item
      if (hasPrepGroup) {
        for (let i = 0; i < item.prep_group.length; i++) {
          let hasPrepItem = item.prep_group[i].prep_item.length > 0;
          if (hasPrepItem) {
            let countPrepItem = item.prep_group[i].prep_item.filter(
              (x) => x.checked,
            );
            let qtySelected = 0;
            for (let j = 0; j < countPrepItem.length; j++) {
              qtySelected += countPrepItem[j].quantity
                ? countPrepItem[j].quantity
                : 1;
            }
            if (qtySelected < item.prep_group[i].choose.min) {
              notFullFilled = true;
              break;
            }
          }
        }
      }

      let hasModifierGroup =
        item.modifier_group && item.modifier_group.length > 0;

      //cek jika ada modifier
      if (hasModifierGroup) {
        for (let i = 0; i < item.modifier_group.length; i++) {
          let hasPrepItem = item.modifier_group[i].modifiers.length > 0;
          if (hasPrepItem) {
            let countPrepItem = item.modifier_group[i].modifiers.filter(
              (x) => x.checked,
            );
            let qtySelected = 0;
            for (let j = 0; j < countPrepItem.length; j++) {
              qtySelected += countPrepItem[j].quantity
                ? countPrepItem[j].quantity
                : 1;
            }
            if (qtySelected < item.modifier_group[i].choose.min) {
              notFullFilled = true;
              break;
            }
          }
        }
      }
    } else {
      notFullFilled = true;
    }

    return notFullFilled;
  };

  return (
    <ReDialog
      title={getLocalState('selectedItem').plu_name}
      closeModal={closeModal}
      useCloseButton={true}
      backgroundStyle={{ background: getColorApp().backgroundColor, color: getColorApp().primaryFontColor }}
      content={
        <Box display="flex" flexDirection="column">
          {getSetting() &&
            getSetting().isImageDetail &&
            getLocalState('selectedItem').url && (
              <Box display="flex" flexDirection="column">
                {getSetting().isShowImageType === 'auto' ? (
                  <Box display="flex" flexDirection="column">
                    <ReLazyImage
                      url={getImageFullPage(getLocalState('selectedItem').url)}
                    />
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="center">
                    <Typography
                      component="button"
                      variant="caption"
                      className={classes.buttonImage}
                      style={{
                        borderColor: getColorApp().secondaryThemeColor,
                        outlineColor: getColorApp().secondaryThemeColor,
                        backgroundColor: getColorApp().secondaryThemeColor,
                        color: getColorApp().primaryFontColor,
                      }}
                      onClick={() => {
                        updateLocalState({
                          isShowImage: !getLocalState('isShowImage'),
                        });
                      }}>
                      {getLocalState('isShowImage')
                        ? `${t('closeImage')}`
                        : `${t('showImage')}`}
                    </Typography>
                  </Box>
                )}
                {getLocalState('isShowImage') && (
                  <Box display="flex" flexDirection="column">
                    <ReLazyImage
                      url={getImageFullPage(getLocalState('selectedItem').url)}
                    />
                  </Box>
                )}
              </Box>
            )}

          {getLocalState('selectedItem').description && (
            <Grid item>
              <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>
                {getLocalState('selectedItem').description}
              </Typography>
            </Grid>
          )}

          {getSetting() &&
            getSetting().isOrderable &&
            getLocalState('selectedItem').use_note && (
              <Grid item style={{ marginTop: theme.spacing(2) }}>
                <TextField
                  inputProps={{ maxLength: 36 }}
                  className={classes.additionalRequest}
                  autoComplete="off"
                  id="addtional"
                  label={t('additionalRequest')}
                  placeholder={t('exampleRequest')}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            )}

          {getLocalState('selectedItem').sub_product &&
            getLocalState('selectedItem').sub_product.length > 0 && (
              <Grid item style={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePackageItem
                  subProduct={getLocalState('selectedItem').sub_product}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').variant_item &&
            getLocalState('selectedItem').variant_item.length > 0 && (
              <Grid item style={{ width: '100%', marginTop: theme.spacing(2) }}>
                <ReVariantAccordion
                  variantOptionTitle={
                    getLocalState('selectedItem').variant_option_title
                  }
                  variantItem={getLocalState('selectedItem').variant_item}
                  checkItem={(groupIndex, itemIndex) => {
                    handleCheckVariant(groupIndex, itemIndex);
                  }}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').is_variant &&
            getLocalState('indexVariantSelected') !== -1 &&
            getLocalState('selectedItem').variant_item[
              localState.indexVariantSelected
            ].use_note && (
              <Grid item style={{ marginTop: theme.spacing(4) }}>
                <TextField
                  inputProps={{ maxLength: 36 }}
                  className={classes.additionalRequest}
                  autoComplete="off"
                  id="addtional"
                  label={t('additionalRequest')}
                  placeholder={t('exampleRequest')}
                  variant="outlined"
                  size="small"
                />
              </Grid>
            )}

          {getLocalState('selectedItem').is_variant &&
            getLocalState('indexVariantSelected') !== -1 &&
            getLocalState('selectedItem').variant_item[
              localState.indexVariantSelected
            ]?.modifier_group?.length > 0 && (
              <Grid item style={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePrepItemAccordion
                  qtyProduct={getLocalState('qty')}
                  prepGroup={
                    getLocalState('selectedItem').variant_item[
                      localState.indexVariantSelected
                    ].modifier_group
                  }
                  checkItem={(groupIndex, itemIndex) => {
                    handleCheckModifier(groupIndex, itemIndex);
                  }}
                  isOrderable={getSetting() && getSetting().isOrderable}
                  isModifier={true}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').is_variant &&
            getLocalState('indexVariantSelected') !== -1 &&
            getLocalState('selectedItem').variant_item[
              localState.indexVariantSelected
            ]?.prep_group?.length > 0 && (
              <Grid item style={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePrepItemAccordion
                  qtyProduct={getLocalState('qty')}
                  prepGroup={
                    getLocalState('selectedItem').variant_item[
                      localState.indexVariantSelected
                    ].prep_group
                  }
                  checkItem={(groupIndex, itemIndex) =>
                    handleCheckPrepItem(groupIndex, itemIndex)
                  }
                  changeQuantity={(groupIndex, itemIndex, prepQty) =>
                    handleChangePrepQuantity(groupIndex, itemIndex, prepQty)
                  }
                  isOrderable={getSetting() && getSetting().isOrderable}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').modifier_group &&
            getLocalState('selectedItem').modifier_group.length > 0 && (
              <Grid item style={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePrepItemAccordion
                  qtyProduct={getLocalState('qty')}
                  prepGroup={getLocalState('selectedItem').modifier_group}
                  checkItem={(groupIndex, itemIndex) => {
                    handleCheckModifier(groupIndex, itemIndex);
                  }}
                  isOrderable={getSetting() && getSetting().isOrderable}
                  isModifier={true}
                />
              </Grid>
            )}

          {getLocalState('selectedItem').prep_group &&
            getLocalState('selectedItem').prep_group.length > 0 && (
              <Grid item style={{ width: '100%', marginTop: theme.spacing(2) }}>
                <RePrepItemAccordion
                  qtyProduct={getLocalState('qty')}
                  prepGroup={getLocalState('selectedItem').prep_group}
                  checkItem={(groupIndex, itemIndex) =>
                    handleCheckPrepItem(groupIndex, itemIndex)
                  }
                  changeQuantity={(groupIndex, itemIndex, prepQty) =>
                    handleChangePrepQuantity(groupIndex, itemIndex, prepQty)
                  }
                  isOrderable={getSetting() && getSetting().isOrderable}
                />
              </Grid>
            )}
          <Grid item>
            <Grid
              container
              direction="row"
              className={classes.priceAndQtyContainer}>
              <Grid ref={noderef} className={classes.marginTopBottom} item>
                {getSetting() && getSetting().isOrderable && !isRewardsPage && (
                  <ReButtonQuantity
                    onChangeValue={(value) => handleChangeQuantity(value)}
                  />
                )}
              </Grid>
              <Grid
                item
                className={`${classes.price} ${classes.marginTopBottom}`}>
                <Box display="flex" flexDirection="row">
                  <Typography
                    variant="subtitle2"
                    className={classes.marginLeftRight}>
                    {getSetting() && getSetting().isOrderable
                      ? 'Sub total : '
                      : `${t('price')} : `}
                  </Typography>
                  <Typography variant="subtitle2">
                    {convertCurrency(
                      sumTotalPriceWithPrepItem(
                        getLocalState('selectedItem'),
                        getLocalState('qty'),
                        getSellBandId(),
                      ),
                    )}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          {getSetting() && getSetting().isOrderable && (
            <Button
              onClick={() => handleAddToCart()}
              className={classes.buttonAddToCart}
              variant="contained"
              disabled={disabledButton()}>
              {t('add')} <AddShoppingCart />
            </Button>
          )}
        </Box>
      }
    />
  );
};

export default ReAddToCartModal;
