import React, { useState, useContext, useRef } from 'react';
import { useGeneralContext } from 'context/GeneralContext';
import { useTranslation } from 'react-i18next';
import localStorageNames from 'data/localStorageNames';
import { useHistory } from 'react-router-dom';

// realm
import * as Realm from 'realm-web';
import { generalErrorHandler } from 'functions/errorHandler';
import { sendErrorData } from 'functions/ErrorMessagesSender';
import URLS from 'URLS';
import {
  getCredential,
  getOrderMethod,
  getOutletData,
  getOutletId,
  getSettingData,
  getTableName,
} from 'data/localStorageGetter';
const REALM_APP_ID =
  process.env[`REACT_APP_REALM_CONFIGURATION_${process.env.REACT_APP_ENV}`];
const realmApp = new Realm.App({ id: REALM_APP_ID });

export const RealmContext = React.createContext([{}, () => {}]);

const RealmProvider = ({ children }) => {
  const history = useHistory();
  const { openGeneralModal } = useGeneralContext();
  const { t } = useTranslation();
  const [state, setState] = useState({
    transaction: {},
    realmCredential: {},
    firstOpenApp: true,
    messageRetry: '',
  });

  let userLogin = null;
  let intervalCount = 0;

  const launch = useRef();

  const setLocalState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getLocalState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const getLocalStorageCredential = () => {
    const credential = localStorage.getItem(localStorageNames.CREDENTIAL);
    if (credential) {
      return JSON.parse(credential);
    }
    return null;
  };

  const getIsRealmLogin = () => {
    const credential = getLocalStorageCredential();
    if (credential) {
      return credential?.isRealmLogin ? credential?.isRealmLogin : false;
    }
    return null;
  };

  const setFirstTimeLoginRealm = (value) => {
    const oldCreds = getLocalStorageCredential();
    if (oldCreds) {
      const newCreds = { ...oldCreds, isRealmLogin: value };
      localStorage.setItem(
        localStorageNames.CREDENTIAL,
        JSON.stringify(newCreds),
      );
    }
  };

  const loginRealm = async () => {
    try {
      if ((getCredential() && getOutletId()) || !realmApp?.currentUser) {
        const credentials = Realm.Credentials.function({
          outlet_id: getOutletId(),
        });
        userLogin = await realmApp.logIn(credentials);
        setLocalState({ realmCredential: userLogin });
        return userLogin;
      } else {
        return realmApp.currentUser;
      }
    } catch (error) {
      // generalErrorHandler(error, url, callback);
      let unexpectedError = generalErrorHandler(error, null, () =>
        openGeneralModal({
          title: t('networkError'),
          content: t('generalErrorHandlerMessage'),
        }),
      );

      if (unexpectedError) {
        sendErrorData({
          message: `${error.message} | ${
            userLogin
              ? JSON.stringify(userLogin.json())
              : 'null userlogin from server'
          }`,
          table: getTableName(),
          file: 'RealmContext.js',
          func: 'loginRealm()',
        });
      }

      return null;
    }
  };

  let reconnectCounter = 0;
  const reconnectRealm = async () => {
    let result = new Promise(async (resolve) => {
      reconnectCounter++;
      // COBA RECON 2X
      if (reconnectCounter < 3) {
        let resultLoginRealm = await loginRealm();
        if (resultLoginRealm) {
          // JIKA LANGSUNG SUKSES, RESOLVE
          reconnectCounter = 0;
          resolve(resultLoginRealm);
        } else {
          // JIKA LOGIN NULL/UNDEFINED, RECURSIVE RECONNECT
          // JIKA ADA RESPONSE, RESOLVE
          let recursiveRecon = await reconnectRealm();
          if (recursiveRecon) {
            reconnectCounter = 0;
            resolve(recursiveRecon);
          }
        }
      } else {
        // LAST HANDLE JIKA TIDAK ADA RESPONSE DARI RECONNECT
        // RESOLVE UNDEFINED AGAR DI HANDLE OLEH PEMANGGIL FUNCTION
        resolve();
      }
    });

    return result;
  };

  const realmConnection = async (onlyPassUser) => {
    let credentials = realmApp?.currentUser;
    if (!credentials) {
      let resultLoginRealm = await loginRealm();
      if (resultLoginRealm) {
        credentials = resultLoginRealm;
      } else {
        let reconnect = await reconnectRealm();
        if (reconnect) {
          credentials = reconnect;
        } else {
          throw new Error('failed-reconnect');
        }
      }
    }

    if (credentials) {
      // onlyPassUser dibutuhkan
      // karena reusable function, jika mau akses db, maka realmconnection.db()
      // perlu di inisiasi mongoclientnya
      // sedangkan untuk akses callFunction, tidak perlu inisiasi mongoclient, return langsung credentialnya
      const connection = onlyPassUser
        ? credentials
        : credentials.mongoClient('mongodb-atlas');

      // console.log('order_method ', getOrderMethod());

      if (
        !getIsRealmLogin() &&
        (getOrderMethod() === 'normal_order' ||
          getOrderMethod() === 'waiter_order')
      ) {
        let validStore = await checkValidStore(credentials);
        if (!validStore) {
          launch.current = setInterval(async () => {
            validStore = await checkValidStore(credentials);
            intervalCount++;

            if (validStore) {
              clearInterval(launch.current);
            }

            if (intervalCount === 2) {
              if (!validStore) {
                history.replace(URLS.CONNECTION_FAILURE, {
                  type: 'valid_table',
                });
              }
              clearInterval(launch.current);
            }
          }, 1000);
        }
      }
      setFirstTimeLoginRealm(true);
      return connection;
    } else if (!credentials && !getIsRealmLogin()) {
      // Jika koneksi gagal DAN koneksi pertama kali
      // HANDLE NULL CREDENTIAL MONGOCLIENT
      setFirstTimeLoginRealm(false);
      throw new Error('null credential');
    }
  };

  const checkIsLogin = () => {
    return realmApp?.currentUser;
  };

  const getTransactionsId = () => {
    return JSON.parse(localStorage.getItem(localStorageNames.TRANSACTIONS_ID));
  };

  const saveTransactionsId = async (transactionId) => {
    let tempTransactionsId = [];
    if (getTransactionsId()) {
      tempTransactionsId = getTransactionsId();
    }
    tempTransactionsId.push(transactionId);

    localStorage.setItem(
      localStorageNames.TRANSACTIONS_ID,
      JSON.stringify(tempTransactionsId),
    );
  };

  const checkValidStore = async (currentUser) => {
    // param currentUser dipassing untuk handle jika realmApp.currentUser null
    // karena pemanggilan checkValidStore, sudah di valdiasi credentialnya ada

    let result;
    try {
      let outletInfo = JSON.parse(getOutletData());
      result = await currentUser.callFunction(
        'checkValidStore',
        outletInfo.tableId,
      );
      return result;
    } catch (error) {
      // generalErrorHandler(error, url, callback);
      let unexpectedError = generalErrorHandler(error, null, () =>
        openGeneralModal({
          title: t('networkError'),
          content: t('generalErrorHandlerMessage'),
        }),
      );

      if (unexpectedError) {
        sendErrorData({
          message: `${error.message} | ${
            result ? JSON.stringify(result.json()) : 'null result from server'
          }`,
          table: getTableName(),
          file: 'RealmContext.js',
          func: 'checkValidStore()',
        });
      }

      return false;
    }
  };

  const callClientTransaction = async (payloadData) => {
    const result = await (
      await realmConnection(true)
    ).callFunction('clientTransaction', {
      method: 'POST',
      data: {
        ...payloadData,
      },
    });
    return result;
  };

  const promiseHitRealmTransaction = (payloadData, counter) => {
    return new Promise(async (resolve, reject) => {
      if (counter === 3) {
        reject({ message: 'failed to fetch' });
        return;
      }
      const result = await callClientTransaction(payloadData);
      if (result) {
        resolve(result);
      } else {
        setTimeout(async () => {
          try {
            counter++;
            const result = await promiseHitRealmTransaction(
              payloadData,
              counter,
            );
            resolve(result);
          } catch (error) {
            reject(error);
          }
        }, 1000);
      }
    });
  };

  const hitRealmTransaction = async (payloadData) => {
    if (getCredential()) {
      let result;
      try {
        result = await promiseHitRealmTransaction(payloadData, 0);
        await saveTransactionsId(result);
        return result;
      } catch (error) {
        // generalErrorHandler(error, url, callback);
        let unexpectedError = generalErrorHandler(error, null, () =>
          openGeneralModal({
            title: t('networkError'),
            content: t('generalErrorHandlerMessage'),
          }),
        );

        if (unexpectedError) {
          sendErrorData({
            message: `${error.message} | ${
              result ? JSON.stringify(result.json()) : 'null result from server'
            }`,
            table: getTableName(),
            file: 'RealmContext.js',
            func: 'hitRealmTransaction()',
          });
        }

        return { errorMessage: error.message };
      }
    }
  };

  const hitRealmTransaction2 = async (payloadData) => {
    if (getCredential()) {
      let result;
      try {
        result = await (
          await realmConnection(true)
        ).callFunction('clientTransactionV2', {
          method: 'POST',
          data: {
            ...payloadData,
          },
        });
        return result;
      } catch (error) {
        // generalErrorHandler(error, url, callback);
        let unexpectedError = generalErrorHandler(error, null, () =>
          openGeneralModal({
            title: t('networkError'),
            content: t('generalErrorHandlerMessage'),
          }),
        );

        if (unexpectedError) {
          sendErrorData({
            message: `${error.message} | ${
              result ? JSON.stringify(result.json()) : 'null result from server'
            }`,
            table: getTableName(),
            file: 'RealmContext.js',
            func: 'hitRealmTransaction2()',
          });
        }

        return null;
      }
    } else {
      return null;
    }
  };

  const hitRealmTransactionStripe = async (data) => {
    const credentials = Realm.Credentials.function({
      outlet_id: data.outletId,
    });
    const user = await realmApp.logIn(credentials);
    const result = await user.callFunction('stripePaymentSucces', {
      method: 'POST',
      _id: data.id,
      SubFunctionID: parseInt(data.subFunctionID),
    });
    // result true || false
    return result;
  };

  const getUserLogin = async () =>{
    const credentials = Realm.Credentials.function(getCredential());
    return await realmApp.logIn(credentials);
  }

  const isLastTransactionExist = async (filter) => {
    const userLogin = await getUserLogin()
    const response = await userLogin.callFunction('clientGetBill', {
      method: 'IS_LAST_TRANSACTION_EXISTS',
      filter: { ...filter },
    });
    return response;
  };

  const getLastTransaction = async (filter) => {
    const userLogin = await getUserLogin()
    const response = await userLogin.callFunction('clientGetBill', {
      method: 'GET_LAST_TRANSACTION',
      filter: { ...filter },
    });
    return response;
  };

  const getDetailTransaction = async (filter) => {
    const userLogin = await getUserLogin()
    const response = await userLogin.callFunction('clientGetBill', {
      method: 'GET_DETAIL',
      filter: { ...filter },
    });
    return response;
  };

  const getHistoryTransaction = async (filter) => {
    const userLogin = await getUserLogin()
    const response = await userLogin.callFunction('clientGetBill', {
      method: 'HISTORY',
      filter: { ...filter },
    });
    return response;
  };

  const getHistoryPaymentOrder = async () => {
    let outletInfo = JSON.parse(getOutletData());
    let transactionsId = getTransactionsId();
    const mapTransId = transactionsId.map((data) => {
      return new Realm.BSON.ObjectId(data);
    });
    const filterById = {
      $in: mapTransId,
    };
    const userLogin = await getUserLogin()
    const response = await userLogin.callFunction('clientGetBill', {
      method: 'HISTORY_VIEW',
      filter: { _id: filterById, table_id: outletInfo.tableId },
    });
    return response;
  };

  const insertLogStore = async (prm = '', getCollection) => {
    const dataLog = {
      _id: new Realm.BSON.ObjectID(),
      _partition: getOutletId(),
      createdAt: new Date(),
      status: 'miniapp_check',
    };

    if (prm !== '') {
      dataLog['parameter'] = prm;
    }

    await getCollection.insertOne(dataLog);

    const queryFilter = {
      filter: {
        'fullDocument._partition': getOutletId(),
        'fullDocument._id': dataLog._id,
      },
    };

    return queryFilter;
  };

  const updateTable = async (getCollection) => {
    const outletInfo = JSON.parse(getOutletData());

    await getCollection.updateOne(
      {
        _partition: getOutletId(),
        table_id: outletInfo.tableId,
      },
      {
        $set: {
          updatedAt: new Date(),
          status: 'miniapp_check',
        },
      },
    );

    const queryFilter = {
      filter: {
        'fullDocument._partition': getOutletId(),
        'fullDocument.table_id': outletInfo.tableId,
      },
    };

    return queryFilter;
  };

  let miniAppCounter = 1;
  const watchListener = async (prm, from, getCollection, queryFilter) => {
    const watchCollection = getCollection.watch(queryFilter);

    let timeout = setTimeout(() => {
      if (from === 'confirm') {
        let retry = miniAppCounter + 1;
        setLocalState({ messageRetry: retry === 2 ? '.' : '..' });
        if (miniAppCounter < 3) {
          const miniappAvailable = checkMiniAppConnection(prm, 'confirm');
          if (!miniappAvailable) {
            miniAppCounter = 1;
            return;
          }
        } else if (miniAppCounter === 3) {
          miniAppCounter = 1;
          watchCollection.return();
          window.location.replace(URLS.MINIAPP_FAILURE);
        }
        miniAppCounter++;
      } else {
        watchCollection.return();
        window.location.replace(URLS.MINIAPP_FAILURE);
      }
    }, 20000);

    let result = null;
    for await (const change of watchCollection) {
      let dataChanges = change.fullDocument;

      if (
        dataChanges &&
        dataChanges._id &&
        dataChanges.status === 'miniapp_ok'
      ) {
        clearTimeout(timeout);
        result = true;
        break;
      }
    }
    return result;
  };

  const checkMiniAppNormalOrder = async (
    prm,
    from,
    collection,
    getCollection,
  ) => {
    let queryFilter =
      collection === 'tables'
        ? updateTable(getCollection)
        : insertLogStore('', getCollection);
    let resultWatch = watchListener(prm, from, getCollection, queryFilter);
    return resultWatch;
  };

  const checkMiniAppQuickOrder = async (prm, from, getCollection) => {
    let queryFilter = insertLogStore(prm, getCollection);
    let resultWatch = watchListener(prm, from, getCollection, queryFilter);
    return resultWatch;
  };

  const checkMiniAppConnection = async (prm = '', from = '') => {
    let outletInfo = JSON.parse(getOutletData());
    try {
      const collection =
        getSettingData().miniAppCheck === 'table' ? 'tables' : 'store_log';
      const getCollection = (await realmConnection())
        .db('RDO')
        .collection(collection);

      if (
        (outletInfo && getOrderMethod() === 'normal_order') ||
        (outletInfo &&
          getOrderMethod() === 'quick_order' &&
          !getSettingData().isStore)
      ) {
        let result = checkMiniAppNormalOrder(
          prm,
          from,
          collection,
          getCollection,
        );
        return result;
      } else if (
        getOrderMethod() === 'quick_order' &&
        getSettingData().isStore
      ) {
        let result = checkMiniAppQuickOrder(prm, from, getCollection);
        return result;
      } else {
        return true;
      }
    } catch (error) {
      let unexpectedError = generalErrorHandler(error, null, () =>
        openGeneralModal({
          title: t('networkError'),
          content: t('generalErrorHandlerMessage'),
        }),
      );

      if (unexpectedError) {
        sendErrorData({
          message: `${error.message} `,
          table: getTableName(),
          file: 'RealmContext.js',
          func: 'checkMiniAppConnection()',
        });
      }
    }
  };

  const lastMiniAppConnection = async () => {
    try {
      if (getSettingData().miniAppCheck === 'store') {
        if (getCredential()) {
          const getCollection = (await realmConnection())
            .db('RDO')
            .collection('store_log');

          const time = new Date();
          time.setMinutes(time.getMinutes() - 10);
          const countData = await getCollection.count({
            status: 'miniapp_ok',
            _partition: getOutletId(),
            createdAt: { $gt: time },
          });
          return countData > 0;
        }
      } else {
        return true;
      }
    } catch (error) {
      let unexpectedError = generalErrorHandler(error, null, () =>
        openGeneralModal({
          title: t('networkError'),
          content: t('generalErrorHandlerMessage'),
        }),
      );

      if (unexpectedError) {
        sendErrorData({
          message: `${error.message} `,
          table: getTableName(),
          file: 'RealmContext.js',
          func: 'lastMiniAppConnection()',
        });
      }
    }
  };

  const sendClientPayment = async (
    payloadData,
    transactionId,
    subFunctionId,
  ) => {
    if (getCredential()) {
      const result = await (
        await realmConnection(true)
      ).callFunction('clientPayment', {
        method: 'POST',
        data: {
          ...payloadData,
        },
        id_transaction: transactionId,
        SubFunctionID: parseInt(subFunctionId),
      });
      if (result) {
        saveTransactionsId(transactionId);
      }
      return result;
    }
  };

  const syncXenditStatusPayment = async (idTransaction) => {
    if (getCredential()) {
      const value = await (
        await realmConnection(true)
      ).callFunction('syncXenditStatusPayment', {
        _id: idTransaction.toString(),
      });
      return value;
    }
  };

  return (
    <RealmContext.Provider
      value={{
        getLocalState,
        setLocalState,
        hitRealmTransaction,
        hitRealmTransaction2,
        hitRealmTransactionStripe,
        sendClientPayment,
        getTransactionsId,
        syncXenditStatusPayment,
        saveTransactionsId,
        isLastTransactionExist,
        getLastTransaction,
        getDetailTransaction,
        getHistoryTransaction,
        getHistoryPaymentOrder,
        loginRealm,
        checkIsLogin,
        checkValidStore,
        checkMiniAppConnection,
        lastMiniAppConnection,
      }}>
      {children}
    </RealmContext.Provider>
  );
};

export const useRealmContext = () => {
  const value = useContext(RealmContext);
  if (value == null) {
    throw new Error('useCartContext() called outside of a Provider?');
  }
  return value;
};

export default RealmProvider;
