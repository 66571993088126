import localStorageNames from './localStorageNames';
import {
  saveColorData,
  saveFeedbackData,
  saveLogo,
  saveOutletData,
  savePaymentMethod,
  saveProductData,
  saveSettingData,
  setLocalStorage,
} from './localStorageSetter';

const insertProduct = (category) => {
  let products = [];
  category.products.forEach((product) => {
    let tempProduct = { ...product };
    delete tempProduct.modifier_group;
    delete tempProduct.prep_group;
    delete tempProduct.sub_product;
    delete tempProduct.variant_item;
    products.push(tempProduct);
    setLocalStorage(
      `product.${product.uuid_product}`,
      JSON.stringify({
        modifier_group: product.modifier_group,
        prep_group: product.prep_group,
        sub_product: product.sub_product,
        variant_item: product.variant_item,
      }),
    );
  });
  setLocalStorage(
    `product.${category.uuid_product_category}`,
    JSON.stringify(products),
  );
};

const spreadProductData = (data) => {
  if (Array.isArray(data) && data.length > 0) {
    let categories = [];
    data.forEach((category) => {
      if (
        Array.isArray(category.categories) &&
        category.categories.length > 0
      ) {
        category.categories.forEach((category2) => {
          categories.push({
            uuid_product_category: category2.uuid_product_category,
            url: category2.url,
            category_name: category2.category_name,
            menu_type: category2.menu_type,
          });
          if (
            Array.isArray(category2.products) &&
            category2.products.length > 0
          ) {
            insertProduct(category2);
          }
        });
      } else {
        categories.push({
          uuid_product_category: category.uuid_product_category,
          url: category.url,
          category_name: category.category_name,
          menu_type: category.menu_type,
        });
        if (Array.isArray(category.products) && category.products.length > 0) {
          insertProduct(category);
        }
      }
    });
    setLocalStorage(
      localStorageNames.SPREAD_CATEGORY,
      JSON.stringify(categories),
    );
  }
};

export const setupApp = (data) => {
  // 1. save logo
  // 2. save setting

  const constructSetting = {
    isOrderable: data.config.is_orderable,
    isDisplayCategory: data.config.display_category,
    isDisplaySearch: data.config.display_search,
    isDirectOrder: data.config.direct_order,
    isImageDetail: data.config.image_detail,
    isDisplayLogin: data.config.display_login,
    orderMethod: data.config.order_method || 'normal_order', // normal_order || payment_order || waiter_order || quick_order
    soldOutText: data.config.sold_out_text,
    confirmOrderText: data.config.confirm_order_text || '',
    isStore: data.config.is_store, // untuk mendeteksi qrcode dari qr store apa qr table
    placeholderParameter: data.config.placeholder_parameter,
    isQRStoreParameter: data.config.qrstore_parameter,
    tableName: data.config.tableName,
    subFunctionID: data.config.sub_function_id_stripe,
    qrcodeTitlePage: data.config.qrcode_title_page,
    miniAppCheck: data.config.miniapp_check,
    isShowViewBill: data.config.is_view_bill_active,
    feedbackText: data.config.feedback_text,
    isInfinityScroll: data.config.view_type !== 'tab_menu',
    isShowImageType: data.config.show_image_type,
    isProductOptionFold: data.config.product_option_fold,
    // TODO HANYA SEMENTARA KARENA RF BELUM READY
    maintenance: data.config.maintenance_mode
  };

  const constructOutlet = {
    outletId: data.config.storeUUID,
    openingHours: data.config.openingHours,
    outletName: data.config.restaurantName,
    tableId: data.config.tableID,
    tableName: data.config.tableName,
    salestype: data.config.salestype,
    currency: data.config.currency,
    currencySymbol: data.config.currency_symbol,
    localeCode: data.config.locale_code,
  };

  const constructColorApp = {
    primaryThemeColor: data.config.primary_color || '#FF8A37',
    secondaryThemeColor: data.config.secondary_color || '#FEEDE2',
    primaryFontColor: data.config.primary_font_color || '#000000',
    secondaryFontColor: data.config.secondary_font_color || '#ffffff',
    backgroundColor: data.config.background_color || '#FFE7D7',
    backgroundProduct: data.config.product_background_color || '#FFFFFF',
    headerAccordion: data.config.header_color || '#FF911C',
    successAccordion: data.config.success_color || '#00AA13',
    addButton: data.config.add_to_cart_color || '#00AA13',
    backgroundAccordion: data.config.background_accordion_color || '#FFFFFF'
  };

  if (data.config.logoUrl) {
    saveLogo(data.config.logoUrl);
  }
  saveOutletData(constructOutlet);
  saveSettingData(constructSetting);
  saveColorData(constructColorApp);
  savePaymentMethod(data.config.payment_channels);
  saveFeedbackData(data.feedback);
  saveProductData(data.product);
  spreadProductData(data.product);

  return data;
};
