import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import theme from 'styles/theme';
import timePassing from 'assets/images/time-passing.png';
import ReDialog from 'components/ReDialog';
import { useTranslation } from 'react-i18next';
import { getColorApp } from 'data/localStorageGetter';

const useStyles = makeStyles({
  cover: {
    display: 'flex',
    height: '100%',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  textWaitMoment: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
  },
});

const LoadingPayment = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <ReDialog
      fullScreen={true}
      closeModal={() => { }}
      floatingTitle={false}
      scrollType={'paper'}
      content={
        <Box className={classes.cover}>
          <img alt="image2" src={timePassing} style={{ width: 100 }} />
          <Typography style={{ marginTop: theme.spacing(3) }}>
            {t('thankyou')}
          </Typography>
          <Typography variant="h4">{t('paymentprocess')}</Typography>
          <Typography className={classes.textWaitMoment} style={{color: getColorApp().primaryFontColor}}>
            {t('waitmoment')}
          </Typography>
        </Box>
      }
    />
  );
};

export default LoadingPayment;
