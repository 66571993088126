import React from 'react';
import { Box, ButtonBase, Grid, Typography } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import ReButtonQuantity from 'components/ReButtonQuantity';
import DetailOrder from './DetailOrder';
import { convertCurrency } from 'functions/convertNumber';
import { getPriceBySellBand } from 'functions/getItemPriceBySellBand';
import theme from 'styles/theme';
import { useAuthContext } from 'context/AuthContext';
import { useCartContext } from 'context/CartContext';
import { getColorApp } from 'data/localStorageGetter';

const CartItem = ({
  classes,
  t,
  getSellBandId,
  data,
  changeItemQty,
  removeItem,
  isNormalOrder,
  isPaymentOrder,
}) => {
  const { getProductList } = useAuthContext();
  const { getCartItems } = useCartContext();

  const insertPrepItems = (data) => {
    if (data[0]?.uuid_group) {
      return data.map((item) => item.prep_item).flat();
    } else {
      return data;
    }
  };

  const getItem = (item, key) => {
    let data = item.is_variant
      ? item.variant_item.find((data) => data.checked)
      : item;

    if (data) {
      if (key && key === 'name') {
        return data.plu_name;
      }

      if (
        (data.prep_group && data.prep_group.length > 0) ||
        data.additional_request ||
        (data.sub_product && data.sub_product.length > 0)
      ) {
        return insertPrepItems(data.prep_group);
      }
    }
  };

  const DetailOrderVariant = ({ item }) => {
    let prepGroup = [];

    if (isNormalOrder) {
      prepGroup = getItem(item);
    }

    if (isPaymentOrder) {
      let subProduct = [];

      const getProductIndex = (data) => {
        return data.products.findIndex(
          (product) => product.plu_number === item.plu_number,
        );
      };

      getProductList().forEach((productList, index) => {
        if (
          Array.isArray(productList.categories) &&
          productList.categories.length > 0
        ) {
          productList.categories.forEach((categories) => {
            let productIndex = getProductIndex(categories);

            if (productIndex !== -1) {
              subProduct = categories.products[productIndex].sub_product || [];
            }
          });
        } else {
          let productIndex = getProductIndex(productList);

          if (productIndex !== -1) {
            subProduct =
              getProductList()[index].products[productIndex].sub_product || [];
          }
        }
      });

      prepGroup = insertPrepItems(item.prep_items || []);
      item.additional_request = item.note || [];
      item.sub_product = subProduct || []; // jika tipe paket ada sub product
    }

    return (
      <Grid item style={{ width: '100%', paddingRight: theme.spacing(2) }}>
        <DetailOrder
          classes={classes}
          t={t}
          getSellBandId={getSellBandId || null}
          prepGroup={prepGroup || []}
          additionalRequest={item.additional_request || []}
          subProduct={item.sub_product || []}
          qtyProduct={item.quantity}
          isVariant={item.is_variant}
          isNormalOrder={isNormalOrder}
        />
      </Grid>
    );
  };

  const isProductHaveRewards = (item) => {
    let items = getCartItems();
    items = items.filter((oldItem) => oldItem.promoFrom === item.timestamp);
    return items.length > 0 ? true : false;
  };

  return (
    <Grid container direction="column" className={`${classes.borderingGrid}`}>
      {Array.isArray(data) &&
        data.map((item, index) => (
          <Box
            key={index}
            className={`${classes.cartItem}`}
            style={
              item.promoName !== undefined &&
              item.promoName !== null &&
              item.promoName !== ''
                ? {
                    paddingLeft: theme.spacing(3),
                    paddingTop: theme.spacing(1),
                  }
                : { paddingTop: theme.spacing(4) }
            }>
            <Grid container style={{ display: 'flex' }}>
              {item.promoName !== undefined &&
                item.promoName !== null &&
                item.promoName !== '' && (
                  <Box
                    fontSize={10}
                    style={{
                      padding: `${theme.spacing(0)}px ${theme.spacing(1)}px`,
                      color: getColorApp().secondaryFontColor,
                      backgroundColor: getColorApp().primaryThemeColor,
                      borderRadius: theme.spacing(1),
                    }}>
                    {item.promoName}
                  </Box>
                )}
            </Grid>
            <Grid
              container
              direction="row"
              style={{ justifyContent: 'space-between', display: 'flex' }}>
              <Grid item style={{ width: '65%' }}>
                <Typography variant="body2" className={'clampOnLine2'}>
                  {getItem(item, 'name')}
                </Typography>
              </Grid>
              <Grid item style={{ width: '35%', textAlign: 'right' }}>
                <Typography variant="subtitle2">
                  {isNormalOrder &&
                    convertCurrency(
                      getPriceBySellBand(item, getSellBandId()) * item.quantity,
                    )}
                  {isPaymentOrder &&
                    convertCurrency(item.amount * item.quantity)}
                </Typography>
              </Grid>
            </Grid>
            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              alignItems="start">
              <Box
                display="flex"
                flexGrow={1}
                justifyContent="left"
                alignItems="center">
                <DetailOrderVariant item={item} />
              </Box>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={theme.spacing(6)}>
                {item.promoName !== undefined &&
                  item.promoName !== null &&
                  item.promoName !== '' && (
                    <ButtonBase onClick={() => removeItem(item)}>
                      <Delete
                        style={{
                          fontSize: 20,
                          color: theme.palette.error.main,
                        }}
                      />
                    </ButtonBase>
                  )}
              </Box>
            </Box>
            {!(
              item.promoName !== undefined &&
              item.promoName !== null &&
              item.promoName !== ''
            ) && (
              <Grid container direction="row" className={classes.buttonQtyCart}>
                <Grid item>
                  <ReButtonQuantity
                    onChangeValue={(value) => changeItemQty(item, value)}
                    propsValue={item.quantity}
                    isDisable={isProductHaveRewards(item)}
                  />
                </Grid>
                <Grid item>
                  <ButtonBase onClick={() => removeItem(item)}>
                    <Delete
                      style={{ fontSize: 20, color: theme.palette.error.main }}
                    />
                  </ButtonBase>
                </Grid>
              </Grid>
            )}
          </Box>
        ))}
    </Grid>
  );
};

export default CartItem;
