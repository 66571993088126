import packageJson from '../../package.json';
import localStorageNames from 'data/localStorageNames';
import moment from 'moment-timezone';
import * as Sentry from '@sentry/browser';

export const sendErrorData = async (data) => {
  const outletInfo = JSON.parse(localStorage.getItem(localStorageNames.OUTLET));

  // GET RDO TIME FROM LOCALSTORAGE
  const rdoTime = moment(
    JSON.parse(localStorage.getItem(localStorageNames.CREDENTIAL))[
      'login-stamp'
    ],
    'x',
  ).format('HH:mm');

  Sentry.setTag('RDO_CUSTOMER', `${outletInfo?.outletName}`);
  Sentry.setTag('RDO_VERSION', `${packageJson.version}`);

  Sentry.setContext('ERROR FRONT END WEB', {
    outlet: outletInfo?.outletName,
    outlet_id: outletInfo?.outletId,
    time_login: rdoTime || '-',
    table_name: outletInfo.tableName,
    table_id:
      outletInfo.tableId ||
      localStorage.getItem(localStorageNames.CUSTOM_PARAMETER),
    file: `${data.file} - ${data.func}`,
  });

  Sentry.captureException(new Error(data.message), {
    level: data.level ? data.level : 'error',
  });
};
