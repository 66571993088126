import theme from 'styles/theme';
import localStorageNames from './localStorageNames';

// GET LOCALSTORAGE
export const getLocalStorage = (key) => {
  return localStorage.getItem(key);
};

export const getSettingData = () => {
  return JSON.parse(getLocalStorage(localStorageNames.SETTING));
};

export const getOutletData = () => {
  return getLocalStorage(localStorageNames.OUTLET);
};

export const getSplashData = () => {
  return getLocalStorage(localStorageNames.SPLASH);
};

export const getProductData = () => {
  return getLocalStorage(localStorageNames.PRODUCTS);
};

// GET DATA FROM LOCALSTORAGE
export const getOrderMethod = () => {
  let settings = getSettingData() || {};
  return settings['orderMethod'] || 'normal_order';
};

export const getOutletId = () => {
  let outletInfo = getOutletData();
  outletInfo = JSON.parse(outletInfo) || {};
  return outletInfo.outletId || '';
};

export const getTableName = () => {
  let outletInfo = getOutletData();
  outletInfo = JSON.parse(outletInfo) || {};
  return outletInfo.tableName || '';
};

export const getCredential = () => {
  let tempCred = {};
  let validCred = {};
  let credential =
    JSON.parse(getLocalStorage(localStorageNames.CREDENTIAL)) || {};

  if (
    credential.token !== null &&
    credential.token !== '' &&
    credential.uuid !== null &&
    credential.uuid !== ''
  ) {
    validCred = credential;
  } else if (
    tempCred.token !== null &&
    tempCred.token !== '' &&
    tempCred.uuid !== null &&
    tempCred.uuid !== ''
  ) {
    validCred = tempCred;
  } else {
    if (process.env.REACT_APP_ENV !== 'PRODUCTION') {
      console.log('Dont have credential');
    }
  }

  return validCred;
};

export const getColorApp = () => {
  const themeColor = JSON.parse(getLocalStorage(localStorageNames.THEME_COLOR)) || theme;
  return themeColor;
};

export const getPaymentMethod = () => {
  return JSON.parse(getLocalStorage(localStorageNames.PAYMENT_METHOD));
};

export const isOpen = () => {
  let outletInfo = getOutletData();
  outletInfo = JSON.parse(outletInfo) || {};
  return outletInfo.openingHours;
};

export const getSalesType = () => {
  let outletInfo = getOutletData();
  outletInfo = JSON.parse(outletInfo) || {};
  return outletInfo.salestype || [];
};

export const getActiveSalesType = () => {
  const salesType = getSalesType().sales;
  const sales = salesType.find((sales) => sales.sequence > 0);
  return sales;
};

export const getLogoUrl = (data) => {
  return data || getLocalStorage(localStorageNames.LOGO);
};

export const getSpreadCategories = () => {
  return JSON.parse(getLocalStorage(localStorageNames.SPREAD_CATEGORY));
};

export const getCategoryList = () => {
  const products = getProductData();
  let data =
    Array.isArray(products) &&
    products.map((newData) => {
      return { ...newData, isShow: false };
    });

  return data;
};
