import { Box, Grid, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import theme from 'styles/theme';
import OrderedPackage from './OrderPackage';
import OrderedPrepItem from './OrderPrepItem';

const DetailOrder = ({
  classes,
  t,
  getSellBandId,
  prepGroup = [],
  additionalRequest = [],
  subProduct = [],
  qtyProduct,
  isNormalOrder,
}) => {
  const [expandModifier, setExpandModifier] = useState(false);

  const checkAdditionalRequest = () => {
    return (
      Array.isArray(additionalRequest) &&
      additionalRequest !== null &&
      additionalRequest.length > 0
    );
  };

  const checkSubProduct = () => {
    return (
      Array.isArray(subProduct) && subProduct !== null && subProduct.length > 0
    );
  };

  const checkPrepGroup = () => {
    return (
      Array.isArray(prepGroup) && prepGroup !== null && prepGroup.length > 0
    );
  };

  const checkValue = () => {
    return checkAdditionalRequest() || checkSubProduct() || checkPrepGroup();
  };

  const showDetail = () => {
    setTimeout(() => {
      setExpandModifier(!expandModifier ? true : false);
    }, 400);
  };

  return (
    <Box>
      {checkValue() && (
        <Typography
          component="button"
          variant="caption"
          onClick={() => {
            showDetail();
          }}
          className={`${classes.modifierText} ${classes.expandButtonModifier}`}>
          {expandModifier ? `${t('closeDetail')}` : `${t('showDetail')}...`}
        </Typography>
      )}

      {expandModifier && (
        <>
          {checkPrepGroup() && (
            <OrderedPrepItem
              classes={classes}
              getSellBandId={getSellBandId}
              prepItems={prepGroup}
              qtyProduct={qtyProduct}
              isNormalOrder={isNormalOrder}
            />
          )}

          {checkSubProduct() && (
            <OrderedPackage
              classes={classes}
              subProduct={subProduct}
              qtyProduct={qtyProduct}
            />
          )}

          {checkAdditionalRequest() && (
            <Grid item style={{ marginBottom: theme.spacing(2) }}>
              {Array.isArray(additionalRequest) &&
                additionalRequest.map((note, index) => (
                  <Box>
                    <Typography
                      variant="caption"
                      className={`${classes.modifierText}`}>
                      {`* ${note}`}
                    </Typography>
                  </Box>
                ))}
            </Grid>
          )}
        </>
      )}
    </Box>
  );
};

export default DetailOrder;
