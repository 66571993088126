const localStorageNames = {
  SPLASH: 'splash',
  LOGO: 'logo',
  CREDENTIAL: 'creds',
  OUTLET: 'outlet_information',
  USER: 'user_information',
  ORDERCATEGORY: 'order_category',
  CATEGORY: 'category',
  PRODUCTS: 'list_product',
  CART_ITEMS: 'cart_items',
  PAYMENT_ITEMS: 'payment_items',
  THEME_COLOR: 'theme_color',
  SETTING: 'setting',
  FEEDBACK: 'list_feedback',
  FILLED_FEEDBACK: 'filled_feedback',
  TRANSACTIONS_ID: 'transactions_id',
  VIEW_BILL_ID: 'view_bill_id',
  PAYMENT_METHOD: 'payment_method',
  IS_VIEW_BILL: 'is_view_bill',
  CUSTOM_PARAMETER: 'custom_parameter',
  SPREAD_CATEGORY: 'spread_category',
  LONG_URL: 'long_url',
};

export default localStorageNames;
