import moment from 'moment-timezone';
import localStorageNames from './localStorageNames';

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const setupFirstCredential = (tokenData) => {
  if (tokenData) {
    // LOGIN TIMESTAMP DIGUNAKAN UNTUK CLEARING STORAGE (EXPIRED TOKEN) KETIKA MELEBIHI KETENTUAN
    let loginStamp = moment(new Date()).format('x');
    let data = {
      'login-stamp': loginStamp,
      token: tokenData.token,
      uuid: tokenData.uuid,
    };

    setLocalStorage(localStorageNames.CREDENTIAL, JSON.stringify(data));
  }
};

export const saveSplash = (data) => {
  setLocalStorage(localStorageNames.SPLASH, data);
};

export const saveLogo = (data) => {
  setLocalStorage(localStorageNames.LOGO, data);
};

export const saveSettingData = (data) => {
  setLocalStorage(localStorageNames.SETTING, JSON.stringify(data));
};

export const saveOutletData = (data) => {
  setLocalStorage(localStorageNames.OUTLET, JSON.stringify(data));
};

export const saveTypeUser = (data) => {
  setLocalStorage(localStorageNames.USER, JSON.stringify(data));
};

export const saveColorData = (data) => {
  setLocalStorage(localStorageNames.THEME_COLOR, JSON.stringify(data));
};

export const savePaymentMethod = (data) => {
  setLocalStorage(localStorageNames.PAYMENT_METHOD, JSON.stringify(data));
};

export const saveProductData = (data) => {
  setLocalStorage(localStorageNames.PRODUCTS, JSON.stringify(data));
};

export const saveSpreadCategoryData = (data) => {
  setLocalStorage(localStorageNames.SPREAD_CATEGORY, JSON.stringify(data));
};

export const saveFeedbackData = (data) => {
  setLocalStorage(localStorageNames.FEEDBACK, JSON.stringify(data));
};

export const saveOrderCategoryData = (data) => {
  setLocalStorage(localStorageNames.ORDERCATEGORY, JSON.stringify(data));
};

// CLEAR LOCALSTORAGE
export const clearStorageAfterScan = () => {
  Object.values(localStorageNames).forEach((LSname) => {
    localStorage.removeItem(LSname);
  });
};

export const clearAllStorage = (param) => {
  if (param === 'partial') {
    localStorage.removeItem(localStorageNames.CART_ITEMS);
    localStorage.removeItem(localStorageNames.FILLED_FEEDBACK);
    localStorage.removeItem(localStorageNames.USER);
  } else {
    Object.values(localStorageNames).forEach((LSname) => {
      if (LSname !== localStorageNames.TRANSACTIONS_ID) {
        localStorage.removeItem(LSname);
      }
    });
  }
};
