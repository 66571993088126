import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, LinearProgress, useMediaQuery } from '@material-ui/core';
import localStorageNames from 'data/localStorageNames';
import { fetchSplashConfig } from 'data/realmFetch';
import {
  clearStorageAfterScan,
  saveSplash,
  setupFirstCredential,
} from 'data/localStorageSetter';
import { setupApp } from 'data/setupApp';
import { useHistory } from 'react-router-dom';
import URLS from 'URLS';
import {
  failGetConfig,
  setupConfigRedirect,
} from 'functions/trafficController';
import defaultSplash from 'assets/images/default_splash.png';
import defaultLogo from 'assets/images/default_logo.png';
import { getLogoUrl } from 'data/localStorageGetter';

const useStyles = makeStyles({
  container: {
    margin: '0 auto',
    height: '100vh',
    backgroundColor: '#FFFFFF',
    position: 'relative',
  },
  image: {
    height: '100vh',
    objectFit: 'cover',
    width: '100%',
  },
  linearprogress: {
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    height: 10,
    zIndex: 99,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: (props) => (props.mode ? '#eee' : 'black'),
  },
  colorPrimary: {
    backgroundColor: (props) => (props.mode ? '#020202' : '#eee'),
  },
});

// kenapa search param di split?
// karena kalau full url tidak bisa get parameter PERTAMA (token)
const getSearchParam = window.location.href.split('?')[1];

const getSplashImage = () => {
  const splashFromURL = new URLSearchParams(getSearchParam).get('splash');
  if (splashFromURL) {
    return decodeURIComponent(splashFromURL);
  } else {
    return defaultSplash;
  }
};

const getLogoImage = () => {
  const logoFromURL = new URLSearchParams(getSearchParam).get('logo');
  if (logoFromURL) {
    return decodeURIComponent(logoFromURL);
  } else {
    return defaultLogo;
  }
};

const checkLocalToken = () => {
  const credential = JSON.parse(
    localStorage.getItem(localStorageNames.CREDENTIAL),
  );

  let result = null;

  if (credential) {
    result = {
      token: credential?.token,
      uuid: credential?.uuid,
    };
  }
  return result;
};

const checkURLToken = () => {
  let result = null;

  if (getSearchParam) {
    const newUrl = getSearchParam.replace(/&amp;/g, '&');
    const urlToken = new URLSearchParams(`?${newUrl}`).get('token');
    const urlUUID = new URLSearchParams(`?${newUrl}`).get('uuid');
    const urlTable = new URLSearchParams(`?${newUrl}`).get('table');
    result = {
      token: urlToken,
      uuid: urlUUID || urlTable,
    };
    clearStorageAfterScan();
    saveSplash(getSplashImage());
    getLogoUrl(getLogoImage());
    setupFirstCredential(result);
  } else {
    result = checkLocalToken();
  }
  return result;
};

const fetchData = async (tokenData, history, theme) => {
  if (tokenData) {
    const config = await fetchSplashConfig(tokenData);
    theme(config.config);
    if (config.id) {
      if (
        config.product &&
        Array.isArray(config.product) &&
        config.product?.length > 0
      ) {
        const appData = setupApp(config);
        history.replace(await setupConfigRedirect(appData));
      } else {
        history.replace(await failGetConfig(config));
      }
    } else {
      history.replace(await failGetConfig(config));
    }
  } else {
    history.replace(URLS.INVALID_TOKEN);
  }
};

const SplashScreen = (props) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const classes = useStyles({
    mode: prefersDarkMode,
  });
  const bigSizeScreen = useMediaQuery('(min-width:600px)');
  const history = useHistory();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const tokenData = checkURLToken();
    fetchData(tokenData, history, props.setTheme);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        return Math.min(oldProgress + 2.5, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      className={classes.container}
      style={
        bigSizeScreen ? { maxWidth: 450 } : { width: '100%', minWidth: 300 }
      }>
      <LinearProgress
        className={classes.linearprogress}
        variant="determinate"
        value={progress}
        classes={{ bar: classes.bar, colorPrimary: classes.colorPrimary }}
      />
      <img alt="splash" src={getSplashImage()} className={classes.image} />
    </Box>
  );
};

export default SplashScreen;
