export const getPriceBySellBand = (data, sellBandId, isFromList = false) => {
  let localData = data;
  if (data.is_variant) {
    let checked = data.variant_item.find((data) => data.checked);
    if (checked) {
      localData = checked;
    } else {
      if (isFromList === true) {
        let activeProducts = data.variant_item.filter((data) => data.is_active);
        if (Array.isArray(activeProducts) && activeProducts.length > 0) {
          let index = 0;
          let price = filterSellbandPrice(activeProducts[0], sellBandId);
          for (let jco = 0; jco < activeProducts.length; jco++) {
            if (price > filterSellbandPrice(activeProducts[jco], sellBandId)) {
              price = filterSellbandPrice(activeProducts[jco], sellBandId);
              index = jco;
            }
          }
          localData = activeProducts[index];
        }
      }
    }
  }
  return filterSellbandPrice(localData, sellBandId);
};

//cek sell band pada item, jika tidak ada maka harga di set 0
const filterSellbandPrice = (data, sellBandId) => {
  let price = 0;

  data.sell_bands = data?.sell_bands?.length > 0 ? data.sell_bands : [];

  let filterSellBands = data.sell_bands.reduce((prev, curr) => {
    if (curr?.sell_band_key === sellBandId) {
      prev.push(curr);
    }
    return prev;
  }, []);

  if (filterSellBands.length > 0) {
    filterSellBands.forEach((data) => {
      price = data.sell_band_price;
    });
  }

  return price;
};
