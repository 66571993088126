import React, { useState } from 'react';
import {
  Grid,
  Typography,
  Paper,
  ButtonBase,
  Box,
  TextField,
  withStyles,
  Button,
} from '@material-ui/core';
import { convertCurrency } from 'functions/convertNumber';
import ReDialog from 'components/ReDialog';
import theme from 'styles/theme';
import { ChevronLeft, Delete } from '@material-ui/icons';
import { useGeneralContext } from 'context/GeneralContext';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'context/AuthContext';
import CartItem from './CartItem';
import useStyles from './checkout.styles';
import { getColorApp } from 'data/localStorageGetter';

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: theme.palette.secondary.main,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.secondary.main,
    },
  },
})(TextField);

const CheckoutPage = ({
  closeModal,
  cartItem = [],
  changeItemQty,
  totalPrice,
  totalQty,
  clearCart,
  removeItemInCart,
  onConfirm,
  onViewBill,
}) => {
  const classes = useStyles(getColorApp());
  const { getTypeSalesName, getSellBandId } = useGeneralContext();
  const { getTableName, getSetting } = useAuthContext();
  const { t } = useTranslation();
  const [parameter, setParameter] = useState('');
  const [isErrorTextField, setErrorTextField] = useState(false);

  const handleConfirm = (orderMethod) => {
    if (getSetting().isStore && getSetting().isQRStoreParameter && !parameter) {
      setErrorTextField(true);
      return;
    }
    onConfirm(orderMethod, parameter);
  };

  const handleIput = (event) => {
    setErrorTextField(false);
    setParameter(event.target.value);
  };

  const handleClickConfirm = () => {
    if (getSetting() && getSetting()?.orderMethod) {
      switch (getSetting().orderMethod) {
        case 'normal_order':
        case 'waiter_order':
          onConfirm(getSetting().orderMethod, null);
          break;
        case 'payment_order':
          onViewBill(getSetting().orderMethod);
          break;
        case 'quick_order':
          handleConfirm(getSetting().orderMethod);
          break;

        default:
          onConfirm(getSetting().orderMethod, null);
          break;
      }
    }
  };

  return (
    <ReDialog
      fullScreen={true}
      closeModal={closeModal}
      modalStyle={classes.modalStyle}
      contentStyle={{ padding: `${theme.spacing(2)}px 0` }}
      titleStyle={classes.modalTitle}
      floatingTitle={true}
      scrollType={'paper'}
      title={
        <Box className={classes.innerTitle}>
          <ButtonBase className={classes.iconButton} onClick={closeModal}>
            <ChevronLeft style={{ width: 32, height: 32 }} />
          </ButtonBase>
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {t('orderList').toUpperCase()}
          </Typography>
          <ButtonBase
            onClick={() => {
              clearCart();
            }}
            className={`${classes.iconButton} ${classes.removeButton}`}>
            <Delete style={{ fontSize: 18 }} />
            <Typography style={{ textAlign: 'center', fontSize: 10 }}>
              {t('voidAll')}
            </Typography>
          </ButtonBase>
        </Box>
      }
      content={
        <Paper className={classes.contentContainer} elevation={3}>
          <Box className={classes.innerContentContainer}>
            <Grid className={`${classes.typeSales} `}>
              {getSetting() && getSetting().isStore ? (
                <>
                  {getSetting().isQRStoreParameter && (
                    <CssTextField
                      id="standard-basic"
                      borderColor="transparent"
                      placeholder={
                        getSetting().placeholderParameter
                          ? getSetting().placeholderParameter
                          : t('placeholderParameter')
                      }
                      inputProps={{
                        maxLength: 20,
                        min: 0,
                        style: { textAlign: 'center' },
                      }}
                      helperText={t('helperParameter')}
                      error={isErrorTextField}
                      value={parameter}
                      onChange={(event) => handleIput(event)}
                      autoComplete="off"
                      style={{
                        minWidth: '60%',
                      }}
                    />
                  )}
                </>
              ) : (
                <Typography
                  component="span"
                  variant="body1"
                  style={{ backgroundColor: getColorApp().backgroundColor}}
                  className={classes.typeSalesTitle}>
                  {t('table').toUpperCase()} : {getTableName()}
                </Typography>
              )}
            </Grid>
            <Grid className={`${classes.typeSales} ${classes.borderingGrid}`}>
              <Typography
                component="span"
                variant="body1"
                style={{ backgroundColor: getColorApp().backgroundColor}}
                className={classes.typeSalesTitle}>
                {getTypeSalesName()}
              </Typography>
            </Grid>

            <CartItem
              classes={classes}
              t={t}
              getSellBandId={getSellBandId}
              data={cartItem}
              changeItemQty={changeItemQty}
              removeItem={removeItemInCart}
              isNormalOrder={true}
              isPaymentOrder={false}
            />

            <Grid
              container
              direction="row"
              className={`${classes.borderingGrid} ${classes.footer}`}>
              <Grid item>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                  Total Qty
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                  {totalQty}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              direction="row"
              className={`${classes.borderingGrid} ${classes.footer}`}>
              <Grid item>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                  TOTAL
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>
                  {convertCurrency(totalPrice)}
                </Typography>
              </Grid>
            </Grid>
            <Grid item style={{ width: '100%', textAlign: 'center' }}>
              <Button
                onClick={() => {
                  handleClickConfirm();
                }}
                className={classes.buttonConfirm}
                style={{ color: getColorApp().secondaryFontColor, backgroundColor: getColorApp().primaryThemeColor}}
                variant="contained">
                {t('confirmOrder')}
              </Button>
            </Grid>
          </Box>
        </Paper>
      }
    />
  );
};

export default CheckoutPage;
