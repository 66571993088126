import { saveTypeUser, saveOrderCategoryData } from 'data/localStorageSetter';
import { getSalesType, getActiveSalesType } from 'data/localStorageGetter';
import URLS from 'URLS';
import { miniappWatchListener } from 'data/realmFetch';

export const setupConfigRedirect = async (appData) => {
  const { config } = appData;
  if (config) {
    const miniappReady = await miniappWatchListener(
      appData.miniapp_check,
      appData.miniapp_check_id,
    );

    if (miniappReady) {
      if (config.display_login) {
        return URLS.LOGIN;
      } else {
        saveTypeUser('guest');
        if (getSalesType().active === 1) {
          let localType = getActiveSalesType();
          // KARENA IKON BELUM PERLU UNTUK DISIMPAN
          delete localType.icon;
          saveOrderCategoryData(localType);
          return URLS.MENU_CATALOGUE;
        } else {
          return URLS.ORDER_CATEGORY;
        }
      }
    } else {
      return URLS.MINIAPP_FAILURE;
    }
  }
  return URLS.SESSION_EXPIRED;
};

export const failGetConfig = (response) => {
  const respConfig = {
    expired: URLS.INVALID_TOKEN,
    closed: URLS.OUTLET_CLOSE,
  };

  if (response && respConfig[response]) {
    return respConfig[response];
  }
  return URLS.INVALID_TOKEN;
};
