import React, { useState, useContext, useRef } from 'react';
import ReSnackbar from 'components/ReSnackbar';
import localStorageNames from 'data/localStorageNames';
import ReDialog from 'components/ReDialog';
import LoadingModal from 'components/LoadingModal';

export const GeneralContext = React.createContext([{}, () => {}]);

const initialSnackbar = {
  visible: false,
  type: '', // error, success, warning
  message: '',
};

const GeneralProvider = ({ children }) => {
  const [state, setState] = useState({
    snackbar: initialSnackbar,
  });
  const _refGeneralDialog = useRef();
  const _refLoadingModal = useRef();

  const setContextState = (newData) => {
    setState((prev) => ({
      ...prev,
      ...newData,
    }));
  };

  const getContextState = (key) => {
    if (key) {
      return state[key];
    }
    return state;
  };

  const getLoadingModal = () => {
    return _refLoadingModal.current || null;
  };

  const getGeneralDialog = () => {
    return _refGeneralDialog.current || null;
  };

  const openGeneralModal = (data) => {
    return getGeneralDialog()?.setVisibility(true, data);
  };

  const closeGeneralModal = () => {
    return getGeneralDialog()?.setVisibility(false);
  };

  const setSnackbar = (visible, type, message) => {
    setContextState({
      snackbar: {
        visible: visible || false,
        type: type || '', // error, success, warning
        message: message || '',
      },
    });
  };

  const openSuccessSnackBar = (message) => {
    setSnackbar(true, 'success', message);
  };

  const openErrorSnackBar = (message) => {
    setSnackbar(true, 'error', message);
  };

  const handleCloseSnackbar = () => {
    setContextState({ snackbar: initialSnackbar });
  };

  const getSnackbarVisibility = () => {
    return getContextState('snackbar').visible;
  };

  const openDrawer = () => {
    setContextState({
      drawer: true,
    });
  };

  const closeDrawer = () => {
    setContextState({
      drawer: false,
    });
  };

  const showLoading = (flag) => {
    getLoadingModal().show(flag);
  };

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
  };

  const getLocalStorage = (key) => {
    return localStorage.getItem(key);
  };

  const setUserLocalStorage = (data) => {
    setLocalStorage(localStorageNames.USER, data);
  };

  const setOrderCategoryLocalStorage = (data) => {
    setLocalStorage(localStorageNames.ORDERCATEGORY, data);
  };

  const getSellBandId = () => {
    let sellBandId = getLocalStorage(localStorageNames.ORDERCATEGORY);
    sellBandId = JSON.parse(sellBandId);
    return sellBandId ? sellBandId.sell_band : 0;
  };

  const getTypeSalesName = () => {
    let typesales = getLocalStorage(localStorageNames.ORDERCATEGORY);
    typesales = JSON.parse(typesales);
    return typesales ? typesales.title : '';
  };

  return (
    <GeneralContext.Provider
      value={{
        getContextState,
        setContextState,
        openSuccessSnackBar,
        openErrorSnackBar,
        openDrawer,
        closeDrawer,
        getLocalStorage,
        setLocalStorage,
        setUserLocalStorage,
        setOrderCategoryLocalStorage,
        getTypeSalesName,
        getSellBandId,
        openGeneralModal,
        closeGeneralModal,
        showLoading,
      }}>
      {children}
      {getSnackbarVisibility() && (
        <ReSnackbar
          show={true}
          type={getContextState('snackbar').type || 'success'}
          onClose={() => handleCloseSnackbar()}
          message={
            getContextState('snackbar').message || 'You can set the message'
          }
        />
      )}
      <ReDialog ref={_refGeneralDialog} useForwardRef useCloseButton={true} />
      <LoadingModal ref={_refLoadingModal} />
    </GeneralContext.Provider>
  );
};

export const useGeneralContext = () => {
  const value = useContext(GeneralContext);
  if (value == null) {
    throw new Error('useGeneralContext() called outside of a Provider?');
  }
  return value;
};

export default GeneralProvider;
