import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, ButtonGroup, Button } from '@material-ui/core';
import { Remove, Add } from '@material-ui/icons';
import { getColorApp } from 'data/localStorageGetter';

const useStyles = makeStyles({
  buttonQty: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
});

const ReButtonQuantity = ({
  onChangeValue = () => {},
  propsValue,
  max,
  qtyProduct,
  isDisable,
}) => {
  const classes = useStyles();
  const [value, setValue] = useState(propsValue || 1);

  const handleIncreaseQty = () => {
    setValue(value + 1);
    onChangeValue(value + 1);
  };

  const handleDecreaseQty = () => {
    setValue(value - 1);
    onChangeValue(value - 1);
  };

  useEffect(() => {
    setValue(propsValue || 1);
  }, [propsValue]);

  return (
    <Box className={classes.buttonQty}>
      <ButtonGroup
        size="small"
        variant="contained"
        style={{ height: 25 }}>
        <Button onClick={handleDecreaseQty} disabled={value === 1 || isDisable} style={{ backgroundColor: value !== 1 ? getColorApp().primaryThemeColor : ''}}>
          <Remove
            style={{ fontSize: 16, color: getColorApp().secondaryFontColor }}
          />
        </Button>
        <Button
          disabled
          style={{ color: getColorApp().secondaryFontColor, backgroundColor: getColorApp().primaryThemeColor }}>
          {value * (qtyProduct > 1 ? qtyProduct : 1)}
        </Button>
        <Button onClick={handleIncreaseQty} disabled={value > max || isDisable} style={{ backgroundColor: getColorApp().primaryThemeColor }}>
          <Add style={{ fontSize: 16, color: getColorApp().secondaryFontColor }} />
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default ReButtonQuantity;
