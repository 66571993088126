import React, { useState } from 'react';
import { Typography, Grid, CircularProgress, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CheckCircle } from '@material-ui/icons';
import { useCartContext } from 'context/CartContext';
import theme from 'styles/theme';
import ReDialog from '../../components/ReDialog';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'context/AuthContext';
import QrCodeDialog from './QrCodeDialog';
import { useRealmContext } from 'context/RealmContext';
import { getColorApp } from 'data/localStorageGetter';

const useStyles = makeStyles({
  titleStyle: {
    paddingBottom: 0,
  },
  buttonConfirm: {
    marginTop: 20,
    width: '80%',
    left: `calc(50% - 80% / 2)`,
  },
  button: {
    width: '200px',
    fontWeight: 'bold',
  },
  confirmText: {
    textAlign: 'center',
    whiteSpace: 'pre-line',
  },
});

const SuccessConfirm = ({ classes, t }) => {
  const { forceReset } = useCartContext();
  const { getSetting } = useAuthContext();

  const handleConfirmBtn = () => {
    forceReset();
  };

  return (
    <ReDialog
      modalStyle={{backgroundColor: getColorApp().backgroundColor}}
      title={
        <CheckCircle
          style={{ color: theme.palette.success.main, fontSize: '3rem' }}
        />
      }
      titleStyle={classes.titleStyle}
      content={
        <Grid container direction="column" spacing={8}>
          <Grid item>
            {getSetting().orderMethod === 'waiter_order' && (
              <Typography variant="h4" style={{ textAlign: 'center' }}>
                {t('thankyou')}
              </Typography>
            )}

            <Typography style={{ textAlign: 'center' }}>
              {getSetting().orderMethod === 'waiter_order'
                ? t('waiterOrderComplete')
                : t('orderComplete')}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              onClick={handleConfirmBtn}
              className={classes.buttonConfirm}
              style={{ color: getColorApp().secondaryFontColor, backgroundColor: getColorApp().primaryThemeColor }}
              variant="contained">
              {t('confirm')}
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
};

const ConfirmDialog = ({
  titleDialog,
  messageDialog,
  closeModal,
  confirmTransaction,
  closeQRcode,
  setting,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [confirmed, setConfirmed] = useState(false);
  const [isButtonClick, setButtonClick] = useState(false);
  const [openQRcode, setOpenQRcode] = useState({ visible: false, data: null });
  const { getLocalState } = useRealmContext();
  
  const handleCloseQRcode = () => {
    setOpenQRcode({ visible: false, data: null });
    closeQRcode();
  };

  const getBodyText = () => {
    if (messageDialog) {
      return messageDialog;
    }
    if (setting && setting.orderMethod !== 'payment_order') {
      return setting.confirmOrderText;
    }
    return t('confirmOrderMessage');
  };

  return (
    <>
      <ReDialog
        modalStyle={classes.modalStyle}
        title={titleDialog || t('confirmOrder').toUpperCase()}
        content={
          <Grid container direction="column" spacing={8}>
            <Grid item>
              <Typography className={classes.confirmText}>
                {getBodyText()}
              </Typography>
            </Grid>
            {isButtonClick ? (
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center">
                <CircularProgress style={{color: getColorApp().secondaryFontColor}} />
                <Box>
                  {getLocalState('messageRetry') && (
                    <Typography className={classes.confirmText}>
                      {t('retryConfirm')}{getLocalState('messageRetry')}
                    </Typography>
                  )}
                </Box>
              </Grid>
            ) : (
              <Grid container direction="row">
                <Grid item style={{ width: '50%' }}>
                  <Button
                    onClick={closeModal}
                    className={classes.buttonConfirm}
                    style={{ color: getColorApp().primaryFontColor }}
                    variant="contained">
                    {t('cancel')}
                  </Button>
                </Grid>
                <Grid item style={{ width: '50%' }}>
                  <Button
                    onClick={async () => {
                      setButtonClick(true);
                      let confirmTrans = await confirmTransaction();
                      const transaction = JSON.parse(confirmTrans);
                      if (transaction.status === 'success') {
                        setConfirmed(true);
                        if (setting.orderMethod === 'quick_order') {
                          setOpenQRcode({
                            visible: true,
                            data: transaction.parameter,
                          });
                        }
                      } else {
                        setButtonClick(false);
                      }
                    }}
                    className={classes.buttonConfirm}
                    style={{ color: getColorApp().secondaryFontColor, backgroundColor: getColorApp().primaryThemeColor }}
                    variant="contained">
                    {`${t('confirm')}`}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        }
      />
      {setting.orderMethod === 'quick_order'
        ? confirmed &&
          openQRcode.visible && (
            <QrCodeDialog
              closeModal={() => handleCloseQRcode()}
              data={openQRcode.data}
            />
          )
        : confirmed && <SuccessConfirm classes={classes} t={t} />}
    </>
  );
};

export default ConfirmDialog;
