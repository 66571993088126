import * as Realm from 'realm-web';
import URLS from 'URLS';
import { getSettingData, getCredential } from './localStorageGetter';

const REALM_APP_ID =
  process.env[`REACT_APP_REALM_CONFIGURATION_${process.env.REACT_APP_ENV}`];
const realmApp = new Realm.App({ id: REALM_APP_ID });

export const fetchSplashConfig = async (tokenData) => {
  try {
    const credentials = Realm.Credentials.function(tokenData);
    let userLogin = await realmApp.logIn(credentials);
    const response = await userLogin.callFunction(
      'getDataAuthStore',
      tokenData,
    );
    return response;
  } catch (error) {
    window.location.replace(URLS.CONNECTION_FAILURE);
  }
};

export const realmConnection = (db) => {
  if (db) {
    return realmApp.currentUser.mongoClient('mongodb-atlas').db('RDO');
  }
  return realmApp.currentUser;
};

export const queryCheck = async (collection, id) => {
  const queryResult = await collection.findOne({
    _id: new Realm.BSON.ObjectId(id),
    status: 'miniapp_check',
  });

  return queryResult;
};

const failCheckMiniapp = async (miniappId) => {
  try {
    const credentials = Realm.Credentials.function(getCredential());
    let userLogin = await realmApp.logIn(credentials);
    const response = await userLogin.callFunction(
      'checkMiniapp',
      {
        _id:miniappId,
        miniapp_check:getSettingData().miniAppCheck
      }
    );
    return response;
  } catch (error) {
    window.location.replace(URLS.CONNECTION_FAILURE);
  }
};

let retryCounter = 0;
const retryMiniappWatch = async (miniappId) => {
  const failMiniAppCheck = await failCheckMiniapp(miniappId);
  if (retryCounter < 2) {
    retryCounter += 1;
    return miniappWatchListener(failMiniAppCheck.miniapp_check, failMiniAppCheck.miniapp_check_id);
  } else {
    return false;
  }
};

export const miniappWatchListener = async (miniappCheck, miniappId) => {
  // FLOW
  // response config miniapp check == true, maka harus di cek
  // pengecekan dimulai dari query manual, jika masih miniapp check dilanjutkan dengan ngewatch
  // watch sampai dapet miniapp_ok
  // jika sampai timeout belum dapet, retry 2x lagi

  if (!miniappCheck) {
    return true;
  }

  return await new Promise(async (resolve) => {
    const collection =
      getSettingData().miniAppCheck === 'table' ? 'tables' : 'store_log';
    const getCollection = realmConnection(true).collection(collection);

    const query = await queryCheck(getCollection, miniappId);
    if (query) {
      const watchCollection = getCollection.watch({
        'fullDocument._id': miniappId,
      });

      let timeout = setTimeout(() => {
        watchCollection.return();
        resolve(retryMiniappWatch(miniappId));
      }, 20000);

      let result = false;
      for await (const change of watchCollection) {
        let dataChanges = change.fullDocument;
        if (
          dataChanges &&
          dataChanges._id &&
          dataChanges.status === 'miniapp_ok'
        ) {
          clearTimeout(timeout);
          result = true;
          break;
        }
      }
      resolve(result);
    }
    resolve(true);
  });
};
